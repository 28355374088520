import { connect } from 'react-redux'
import { compose } from 'recompose'

import { switchLaborToDirect } from 'Store/Actions/admin/labors'

import SwitchToDirect from './SwitchToDirect'

export default compose(
  connect(
    null,
    {
      onSwitch: switchLaborToDirect,
    },
  ),
)(SwitchToDirect)
