import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { getProjectById } from 'Store/Selectors/admin/projects'
import {
  activateProject,
  deactivateProject,
} from 'Store/Actions/admin/projects'
import ProjectStatus from './ProjectStatus'

export default connect(
  createStructuredSelector({
    project: (state, props) => getProjectById(props.projectId)(state, props),
  }),
  {
    onActivateProject: activateProject,
    onDeactivateProject: deactivateProject,
  },
)(ProjectStatus)
