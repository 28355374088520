import { createSelector } from 'reselect'
import { getEntities, denormalize } from 'rapidux'

import { getData } from '../data'

export const getState = state => state.admin.clients

export const getClients = getEntities(getState, getData, {
  type: 'clients',
  sorted: true,
})

export const getClientById = clientId =>
  createSelector(
    getData,
    data => (clientId ? denormalize(data, 'clients', clientId) : null),
  )
