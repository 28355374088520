import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Form as FinalForm } from 'react-final-form'
import { Flex } from 'rebass'
import get from 'lodash/get'
import { toast } from 'react-toastify'

import { Title, Button, Modal, Icon, Text } from 'Components/UI'
import { DayPicker } from 'Components/Blocks/Forms'
import { loadingGlyph } from 'Assets/Svg'
import { Toast } from 'Components/Blocks'

class ClientStatusModal extends PureComponent {
  state = { isLoading: false, error: null }

  get isActive() {
    const { subcontractorProvider } = this.props

    return get(subcontractorProvider, 'state') === 'active'
  }

  handleSubmit = async () => {
    const {
      subcontractorProvider,
      onActivateSubcontractorProvider,
      onDeactivateSubcontractorProvider,
    } = this.props
    const { isActive } = this

    this.setState({ isLoading: true, error: null })

    const { ok, error } = isActive
      ? await onDeactivateSubcontractorProvider(
          get(subcontractorProvider, 'id'),
        )
      : await onActivateSubcontractorProvider(get(subcontractorProvider, 'id'))

    if (ok) {
      toast.success(
        <Toast
          heading={`Sub-Contractor Provider ${
            isActive ? 'deactivated' : 'activated'
          }`}
          type="success"
        />,
      )

      this.handleCallback()
    } else if (get(error, 'status') === 422) {
      this.setState({
        error: `Sub-Contractor Provider cannot be ${
          isActive ? 'deactivated' : 'activated'
        }`,
      })
    } else {
      this.setState({ error: 'Something goes wrong' })
    }

    this.setState({ isLoading: false })
  }

  handleCallback = () => {
    const { onCallback } = this.props
    this.setState({ error: null })
    onCallback()
  }

  renderForm = ({ handleSubmit }) => {
    const { subcontractorProvider } = this.props
    const { isLoading, error } = this.state

    return (
      <Fragment>
        <Title.H2 color="brownishGray" mb={4} mt={5} textAlign="center">
          {`${!this.isActive ? 'Activating' : 'Deactivating'} ${get(
            subcontractorProvider,
            'name',
            '',
          )}`}
        </Title.H2>

        <DayPicker label="Effective Date" name="effectiveDate" />

        {error && <Text color="danger">{error}</Text>}

        <Flex my={4}>
          <Button default mr={1} width={1} onClick={this.handleCallback}>
            Cancel
          </Button>

          <Button
            disabled={isLoading}
            ml={1}
            secondary
            width={1}
            onClick={handleSubmit}
          >
            {isLoading && <Icon glyph={loadingGlyph} size={28} />}

            {!this.isActive ? 'Activate' : 'Deactivate'}
          </Button>
        </Flex>
      </Fragment>
    )
  }

  render = () => {
    const { subcontractorProvider } = this.props

    return (
      <Modal
        isOpen={!!subcontractorProvider}
        isOverflow={false}
        onCallback={this.handleCallback}
      >
        <Modal.Close onClick={this.handleCallback} />

        <FinalForm render={this.renderForm} onSubmit={this.handleSubmit} />
      </Modal>
    )
  }
}

ClientStatusModal.defaultProps = { subcontractorProvider: null }
ClientStatusModal.propTypes = {
  subcontractorProvider: PropTypes.object,
  onActivateSubcontractorProvider: PropTypes.func.isRequired,
  onCallback: PropTypes.func.isRequired,
  onDeactivateSubcontractorProvider: PropTypes.func.isRequired,
}

export default ClientStatusModal
