import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Form } from 'react-final-form'
import { Flex, Box } from 'rebass'

import noop from 'lodash/noop'

import { getFilterForm } from 'Store/Selectors/tables'
import { Select, Input, DayPicker } from 'Components/Blocks/Forms'
import { magnifyGlyph } from 'Assets/Svg'
import FormStateToRedux from './FormStateToRedux'

const type = 'admins'

const AdminsFilterForm = ({ initialValues }) => (
  <Form initialValues={initialValues} onSubmit={noop}>
    {({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <FormStateToRedux type={type} />

        <Flex alignItems="center" justifyContent="space-between">
          <Flex alignItems="center" width={[1 / 2, 1 / 2, 1 / 2, 1 / 3]}>
            <Box mr={[2, 3, 3, 4]} width={1 / 2}>
              <Select
                height={30}
                label="Status"
                name="status"
                options={[
                  { value: 'all', label: 'Show All' },
                  { value: 'profileCreated', label: 'Profile created' },
                  {
                    value: 'onboardingCompleted',
                    label: 'Onboarding completed',
                  },
                  { value: 'adminActive', label: 'Active' },
                  { value: 'adminDeactivated', label: 'Deactivated' },
                ]}
                placeholder="Show All"
                selectProps={{
                  isSearchable: false,
                }}
              />
            </Box>

            <Box width={2 / 3}>
              <DayPicker label="Date submitted" name="createdAt" ranged />
            </Box>
          </Flex>

          <Box ml={[2, 3, 3, 4]} width={[1 / 2, 1 / 2, 1 / 2, 1 / 4]}>
            <Input
              icon={magnifyGlyph}
              iconFill="dodgerBlue"
              iconSize={20}
              label="&nbsp;"
              name="search"
              placeholder=""
            />
          </Box>
        </Flex>
      </form>
    )}
  </Form>
)

AdminsFilterForm.propTypes = { initialValues: PropTypes.object.isRequired }

export default connect(
  createStructuredSelector({
    initialValues: (state, props) => getFilterForm(type)(state, props),
  }),
)(AdminsFilterForm)
