import { createFields, createLoadHandler, createReducerHandlers } from 'rapidux'

import { createReducer } from 'Store/utils'

import { LOG_OUT } from 'Store/Actions/viewer'
import { LOAD_ROLES, CREATE_ROLE } from 'Store/Actions/admin/roles'

const initialState = {
  ...createFields('roles'),
}
const handlers = {
  ...createReducerHandlers('roles', LOAD_ROLES, {
    withReplace: true,
  }),

  [CREATE_ROLE.SUCCESS]: createLoadHandler('roles'),

  [LOG_OUT]: state => state.merge(initialState),
}

export default createReducer(initialState, handlers)
