import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { renderRoutes } from 'react-router-config'
import { withRouter, Redirect } from 'react-router'
import { compose } from 'recompose'
import { createStructuredSelector } from 'reselect'
import { Form as FinalForm } from 'react-final-form'
import validate from 'validate.js'
import { toast } from 'react-toastify'

import get from 'lodash/get'
import uniq from 'lodash/uniq'
import difference from 'lodash/difference'

import { withAppContext } from 'Services/Context'

import { Sidebar, Header, Form } from 'Components/Blocks/Onboarding'

import { ONBOARDING_PATHS, ADMIN_ROOT } from 'Constants/paths'
import {
  STEP_NAMES,
  ALL_STEPS,
  INITIAL_VISIBLE_STEPS,
} from 'Constants/onboarding'
import {
  presenceFieldConstraint,
  birthdayConstraint,
  emailConstraint,
  phoneNumberConstraint,
} from 'Constants/constraints'

import {
  updateProfile,
  createPhoneNumber,
  sendVerificationCode,
  verifyPhone,
} from 'Store/Actions/onboarding'
import { getOnboardingProfile } from 'Store/Selectors/onboarding'
import { Toast } from 'Components/Blocks'

import { AppContainer, OnboardingAppContent } from './styles'

class Onboarding extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  get activeStep() {
    const { profile } = this.props
    return get(ALL_STEPS, profile.onboardingSteps.length) || false
  }

  get pathIndex() {
    return Object.keys(ONBOARDING_PATHS).findIndex(key => key === this.pathName)
  }

  get pathName() {
    const {
      location: { pathname },
    } = this.props

    return Object.keys(ONBOARDING_PATHS).find(
      key => ONBOARDING_PATHS[key] === pathname,
    )
  }

  validate = values => {
    switch (this.pathName) {
      case STEP_NAMES.BASIC:
        return validate(values, {
          ...presenceFieldConstraint('lastName'),
          ...presenceFieldConstraint('firstName'),
          ...presenceFieldConstraint('gender'),
          ...birthdayConstraint,
          ...emailConstraint(),
          ...phoneNumberConstraint('alternatePhoneNumber'),
        })
      case STEP_NAMES.PHOTO:
        return validate(values, {
          ...presenceFieldConstraint('personalPhoto'),
        })
      case STEP_NAMES.PHONE:
        return validate(values, {
          ...presenceFieldConstraint('pendingPrimaryPhoneNumber.phoneNumber'),
          ...phoneNumberConstraint(
            'pendingPrimaryPhoneNumber.phoneNumber',
            true,
          ),
        })
      case STEP_NAMES.PHONE_VERIFICATION:
        return validate(values, {
          ...presenceFieldConstraint('verificationCode'),
        })
      default:
        return {}
    }
  }

  getPathByStepIndex = stepIndex => ONBOARDING_PATHS[ALL_STEPS[stepIndex - 1]]

  getDisabledSteps = onboardingSteps =>
    difference(
      ALL_STEPS,
      uniq([...INITIAL_VISIBLE_STEPS, ...onboardingSteps, this.activeStep]),
    )

  goTo = action => {
    const {
      history: { push },
    } = this.props

    const index = this.pathIndex
    const path =
      ONBOARDING_PATHS[
        Object.keys(ONBOARDING_PATHS)[action === 'prev' ? index - 1 : index + 1]
      ]

    if (path) {
      push(path)
    }
  }

  handlePrevClick = () => this.goTo('prev')

  handleChangeStep = step => {
    const {
      history: { push },
    } = this.props

    push(ONBOARDING_PATHS[step])
  }

  handleSubmit = async values => {
    const {
      history: { push },
      profile,
      onUpdateProfile,
      onCreatePhoneNumber,
      onSendVerificationCode,
      onVerifyPhone,
    } = this.props

    const onboardingSteps = uniq([...profile.onboardingSteps, this.pathName])

    if (this.pathName === STEP_NAMES.PHONE) {
      const {
        ok: phoneNumberCreated,
        error: phoneNumberError,
      } = await onCreatePhoneNumber(
        values.pendingPrimaryPhoneNumber.phoneNumber,
      )

      if (phoneNumberCreated) {
        await onUpdateProfile({ ...values, onboardingSteps })

        const {
          ok: verificationCodeSent,
          error: verificationCodeError,
        } = await onSendVerificationCode()

        if (verificationCodeSent) {
          this.goTo('next')
          toast.success(
            <Toast
              heading="Verification code sent to your phone"
              type="success"
            />,
          )
        }

        if (verificationCodeError) {
          if (verificationCodeError.status === 422)
            toast.error(
              <Toast heading="Phone number is invalid" type="error" />,
            )
          else
            toast.error(
              <Toast
                heading="Something went wrong"
                text="Please try again"
                type="error"
              />,
            )
        }
      }

      if (phoneNumberError) {
        if (phoneNumberError.status === 409)
          toast.error(
            <Toast
              heading="Another user has verified this phone number"
              type="error"
            />,
          )
        else
          toast.error(
            <Toast
              heading="Something went wrong"
              text="Please try again"
              type="error"
            />,
          )
      }
    } else if (this.pathName === STEP_NAMES.PHONE_VERIFICATION) {
      const { ok, error } = await onVerifyPhone(values.verificationCode)

      if (ok) {
        await onUpdateProfile({ ...values, onboardingSteps })
        this.goTo('next')
      }

      if (error) {
        if (error.status === 422)
          toast.error(
            <Toast heading="Verification code is incorrect" type="error" />,
          )
        else if (error.status === 409)
          toast.error(
            <Toast
              heading="Another user has verified this phone number"
              type="error"
            />,
          )
        else
          toast.error(
            <Toast
              heading="Something went wrong"
              text="Please try again"
              type="error"
            />,
          )
      }
    } else if (this.pathName === STEP_NAMES.BADGE_AKNOWLEDGEMENT) {
      const acknowledged = get(values, 'onboardingAknowledged')

      if (acknowledged) {
        await onUpdateProfile({
          ...values,
          onboardingSteps,
          onboardingCompleted: true,
          onboardingAknowledged: true,
        })

        toast.success(<Toast heading="Onboarding completed" type="success" />)

        push(ADMIN_ROOT)
      } else {
        toast.error(
          <Toast heading="Your acknowledgement is required." type="error" />,
        )
      }
    } else {
      const { ok, error } = await onUpdateProfile({
        ...values,
        onboardingSteps,
      })

      if (ok) {
        this.goTo('next')
        toast.success(<Toast heading="Profile updated" type="success" />)
      }

      if (error) {
        if (error.status === 422)
          toast.error(<Toast heading="Params are invalid" type="error" />)
        else
          toast.error(
            <Toast heading="Something went wrong" text="Please try again" />,
          )
      }
    }
  }

  renderForm = form => {
    const { route, profile } = this.props

    const completedSteps = profile.onboardingSteps

    const disabledSteps = this.getDisabledSteps(profile.onboardingSteps)

    const step = this.pathName

    if (!step || disabledSteps.includes(step)) {
      return <Redirect to={ONBOARDING_PATHS[this.activeStep]} />
    }

    return (
      <Fragment>
        <Sidebar disabledSteps={disabledSteps} />
        <Header />

        <OnboardingAppContent>
          <Form
            allSteps={ALL_STEPS}
            completedSteps={completedSteps}
            disabledSteps={disabledSteps}
            form={form}
            step={step}
            onChangeStep={this.handleChangeStep}
            onClickBack={this.handlePrevClick}
          >
            {renderRoutes(route.routes)}
          </Form>
        </OnboardingAppContent>
      </Fragment>
    )
  }

  render = () => {
    const { profile } = this.props

    return (
      <AppContainer>
        <FinalForm
          initialValues={profile}
          keepDirtyOnReinitialize
          render={this.renderForm}
          validate={this.validate}
          validateOnBlur={false}
          onSubmit={this.handleSubmit}
        />
      </AppContainer>
    )
  }
}

Onboarding.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired })
    .isRequired,
  profile: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  onCreatePhoneNumber: PropTypes.func.isRequired,
  onSendVerificationCode: PropTypes.func.isRequired,
  onUpdateProfile: PropTypes.func.isRequired,
  onVerifyPhone: PropTypes.func.isRequired,
}

export default compose(
  withAppContext,
  withRouter,
  connect(
    createStructuredSelector({ profile: getOnboardingProfile }),
    {
      onUpdateProfile: updateProfile,
      onCreatePhoneNumber: createPhoneNumber,
      onSendVerificationCode: sendVerificationCode,
      onVerifyPhone: verifyPhone,
    },
  ),
)(Onboarding)
