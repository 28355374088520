import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Form } from 'react-final-form'
import { Flex, Box } from 'rebass'
import noop from 'lodash/noop'

import { getFilterForm } from 'Store/Selectors/tables'
import { DayPicker } from 'Components/Blocks/Forms'
import FormStateToRedux from './FormStateToRedux'

const type = 'checkins'

const CheckinsFilterForm = ({ initialValues, userId, checkinStatus }) => (
  <Form
    initialValues={{ ...initialValues, userId, checkinStatus }}
    onSubmit={noop}
  >
    {({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <FormStateToRedux type={type} />

        <Flex alignItems="center" justifyContent="space-between">
          <Box width={[1 / 2, 1 / 2, 1 / 2, 1 / 4]}>
            <DayPicker label="Date submitted" name="startAt" ranged />
          </Box>
        </Flex>
      </form>
    )}
  </Form>
)

CheckinsFilterForm.propTypes = {
  checkinStatus: PropTypes.string.isRequired,
  initialValues: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
}

export default connect(
  createStructuredSelector({
    initialValues: (state, props) => getFilterForm(type)(state, props),
  }),
)(CheckinsFilterForm)
