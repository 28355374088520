import { createReducer } from 'Store/utils'
import { UPDATE_FILTER_FORM } from 'Store/Actions/ui'

const initialState = {
  adminsFilterForm: {},
  laborsFilterForm: {},
  projectsFilterForm: {},
  checkinsFilterForm: {},
  clientsFilterForm: {},
  subcontractorProvidersFilterForm: {},
  subcontractorProviderLaborsForm: {},
}

const handlers = {
  [UPDATE_FILTER_FORM]: (state, { payload: { type, data } }) =>
    state.merge({ [`${type}FilterForm`]: data }),
}

export default createReducer(initialState, handlers)
