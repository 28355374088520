import { DataTable, ReportTable, SubComponentTable } from './styles'

import HeaderCell from './HeaderCell'
import Pagination from './Pagination'

DataTable.HeaderCell = HeaderCell
DataTable.Pagination = Pagination
DataTable.SubComponentTable = SubComponentTable
DataTable.ReportTable = ReportTable

export default DataTable
