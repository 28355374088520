import styled, { css } from 'styled-components'
import { themeGet } from 'styled-system'
import { Flex, Box } from 'rebass'

export const Container = styled(Flex).attrs({
  bg: 'lightGray',
  py: 3,
  px: 5,
  color: 'brownishGray',
})`
  position: relative;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
  height: 250px;
`

export const TipWrapper = styled(Box).attrs({ mt: 3, mr: 4 })`
  position: absolute;
  right: 0;
  top: 0;
`

export const Panel = styled(Flex).attrs({
  height: '100%',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  px: 2,
})`
  text-align: center;

  ${props =>
    props.border &&
    css`
      border: 1px dashed ${themeGet('colors.brownGray')};
    `}
`

export const Content = styled(Flex)`
  border: 1px dashed #aeaeae;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`
