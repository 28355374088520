import { createSelector } from 'reselect'
import get from 'lodash/get'

export const getState = state => state.tables

export const getFilterForm = type =>
  createSelector(
    getState,
    state => state[`${type}FilterForm`],
  )

export const getAdminsFormSearchValue = createSelector(
  getFilterForm('admins'),
  form => get(form, 'search', ''),
)

export const getLaborFormSearchValue = createSelector(
  getFilterForm('labors'),
  form => get(form, 'search', ''),
)

export const getProjectsFormSearchValue = createSelector(
  getFilterForm('projects'),
  form => get(form, 'search', ''),
)

export const getClientsFormSearchValue = createSelector(
  getFilterForm('clients'),
  form => get(form, 'search', ''),
)

export const getSubcontractorProvidersFormSearchValue = createSelector(
  getFilterForm('subcontractorProviders'),
  form => get(form, 'search', ''),
)

export const getSubcontractorProviderLaborsFormSearchValue = createSelector(
  getFilterForm('subcontractorProviderLabors'),
  form => get(form, 'search', ''),
)
