import { lazy } from 'react'

const ChangePassword = lazy(() =>
  import('./ChangePassword' /* webpackChunkName: "auth-change-password" */),
)

const ConfirmEmail = lazy(() =>
  import('./ConfirmEmail' /* webpackChunkName: "auth-confirm-email" */),
)

const ForgotPassword = lazy(() =>
  import('./ForgotPassword' /* webpackChunkName: "auth-forgot-password" */),
)

const SignIn = lazy(() =>
  import('./SignIn' /* webpackChunkName: "auth-sign-in" */),
)

const SignUp = lazy(() =>
  import('./SignUp' /* webpackChunkName: "auth-sign-up" */),
)

export { ChangePassword, ConfirmEmail, ForgotPassword, SignIn, SignUp }
