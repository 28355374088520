import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Avatar } from 'Components/UI'

import { Container, Name, RemoveIcon } from './styles'

class Labor extends PureComponent {
  state = {}

  handleRemove = () => {
    const { labor, onRemove } = this.props

    onRemove(labor)
  }

  render() {
    const { labor } = this.props

    return (
      <Container>
        <Avatar size={24} src={labor.image} />
        <Name>{labor.label}</Name>
        <RemoveIcon onClick={this.handleRemove} />
      </Container>
    )
  }
}

Labor.propTypes = {
  labor: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
}

export default Labor
