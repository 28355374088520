import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import validate from 'validate.js'
import { toast } from 'react-toastify'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'

import {
  presenceFieldConstraint,
  emailConstraint,
  phoneNumberConstraint,
} from 'Constants/constraints'
import { PHONE_MASK } from 'Constants/masks'
import { ADMIN_PATHS } from 'Constants/paths'
import { getCreatedDataId } from 'Services/Utils'
import { Forms, Toast } from 'Components/Blocks'
import { Title, Button, Modal } from 'Components/UI'
import SubcontractorProvidersSelector from 'Components/Blocks/Admin/ReportSubcontractorProvidersSelector'
import { FormContainer } from '../styles'
import { Error } from './styles'

class NewLabor extends PureComponent {
  state = {
    error: null,
  }

  validate = values =>
    validate(values, {
      ...emailConstraint('email', false),
      ...presenceFieldConstraint('firstName'),
      ...presenceFieldConstraint('lastName'),
      ...phoneNumberConstraint('phoneNumber', true),
      ...presenceFieldConstraint('kind'),
      ...presenceFieldConstraint(
        'subcontractorProvider',
        isEqual(get(values, 'kind.value'), 'subcontractor'),
      ),
    })

  handleInviteLabor = async values => {
    const {
      onCreateLabor,
      onInviteLabor,
      onCallback,
      history: { push },
    } = this.props

    this.setState({ error: null })

    const isInvite = get(values, 'email')
    const result = isInvite
      ? await onInviteLabor(values)
      : await onCreateLabor(values)

    if (result.ok) {
      toast.success(
        <Toast
          heading={`Labor ${isInvite ? 'invited' : 'created'}`}
          type="success"
        />,
      )
      onCallback()

      if (!isInvite) {
        push(ADMIN_PATHS.LABOR_DETAILS(getCreatedDataId(result, 'labors')))
      }
    } else if (get(result, 'error.status') === 409) {
      this.setState({ error: 'User already exists' })
    } else {
      this.setState({
        error: 'Something goes wrong. Please try again later...',
      })
    }
  }

  getKindOptions = () => {
    const options = [
      {
        value: 'direct',
        label: 'Direct Employee',
      },
      { value: 'subcontractor', label: 'Sub-Contractor' },
    ]

    return options
  }

  handleCallback = () => {
    const { onCallback } = this.props
    this.setState({ error: null })
    onCallback()
  }

  renderForm = ({ handleSubmit, invalid, values }) => {
    const { error } = this.state

    return (
      <FormContainer onSubmit={handleSubmit}>
        <Title.H2 color="brownishGray" mb={4} mt={1} textAlign="center">
          Add New Labor
        </Title.H2>

        <Forms.Input
          label="First Name"
          name="firstName"
          placeholder="Enter Labor's First Name"
        />
        <Forms.Input
          label="Last Name"
          name="lastName"
          placeholder="Enter Labor's Last Name"
        />
        <Forms.Input
          label="Email"
          name="email"
          placeholder="Enter Labor's Email"
        />
        <Forms.Input
          label="Phone Number"
          mask={PHONE_MASK}
          name="phoneNumber"
          placeholder="Enter Labor's Phone"
        />
        <Forms.Select
          label="Kind"
          name="kind"
          options={this.getKindOptions()}
          selectProps={{
            isSearchable: false,
          }}
        />
        {isEqual(get(values, 'kind.value'), 'subcontractor') && (
          <SubcontractorProvidersSelector
            label="Name of the company"
            name="subcontractorProvider"
            placeholder="Select Sub-Contractor Provider"
            selectProps={{
              menuPlacement: 'top',
            }}
          />
        )}

        <Button disabled={invalid} mt={4} secondary type="submit" width={1}>
          Add Labor
        </Button>

        {error && <Error>{error}</Error>}
      </FormContainer>
    )
  }

  render() {
    const { isOpen } = this.props

    return (
      <Modal isOpen={isOpen} onCallback={this.handleCallback}>
        <Modal.Close onClick={this.handleCallback} />
        <Form
          render={this.renderForm}
          validate={this.validate}
          onSubmit={this.handleInviteLabor}
        />
      </Modal>
    )
  }
}

NewLabor.propTypes = {
  history: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCallback: PropTypes.func.isRequired,
  onCreateLabor: PropTypes.func.isRequired,
  onInviteLabor: PropTypes.func.isRequired,
}

export default NewLabor
