import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Box } from 'rebass'

export const Error = styled(Box)`
  color: ${themeGet('colors.danger')};
  text-align: center;
`

export default {}
