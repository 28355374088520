import { createSelector } from 'reselect'

export const getState = state => state.ui

export const getSidebarShrinked = createSelector(
  getState,
  state => state.sidebarShrinked,
)

export const getAdminsPaged = createSelector(
  getState,
  state => state.adminsPaged,
)

export const getLaborsPaged = createSelector(
  getState,
  state => state.laborsPaged,
)

export const getProjectsPaged = createSelector(
  getState,
  state => state.projectsPaged,
)

export const getInvitationsPaged = createSelector(
  getState,
  state => state.invitationsPaged,
)

export const getCheckinsPaged = createSelector(
  getState,
  state => state.checkinsPaged,
)

export const getClientsPaged = createSelector(
  getState,
  state => state.clientsPaged,
)

export const getSubcontractorProvidersPaged = createSelector(
  getState,
  state => state.subcontractorProvidersPaged,
)

export const getSubcontractorProviderLaborsPaged = createSelector(
  getState,
  state => state.subcontractorProviderLaborsPaged,
)
