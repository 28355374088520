export const STEP_NAMES = {
  BASIC: 'BASIC',
  PHOTO: 'PHOTO',
  PHONE: 'PHONE',
  PHONE_VERIFICATION: 'PHONE_VERIFICATION',
  BADGE_AKNOWLEDGEMENT: 'BADGE_AKNOWLEDGEMENT',
}

export const ALL_STEPS = [
  STEP_NAMES.BASIC,
  STEP_NAMES.PHOTO,
  STEP_NAMES.PHONE,
  STEP_NAMES.PHONE_VERIFICATION,
  STEP_NAMES.BADGE_AKNOWLEDGEMENT,
]

export const INITIAL_VISIBLE_STEPS = [STEP_NAMES.BASIC]

export const BIRTHDAY_DATE_FORMAT = 'MM/dd/yyyy'
