import { getEntities } from 'rapidux'
import { createSelector } from 'reselect'

import { getData } from '../data'

export const getState = state => state.admin.labors

export const getLabors = getEntities(getState, getData, {
  type: 'labors',
  sorted: true,
})

export const getLabor = getEntities(getState, getData, {
  type: 'labors',
  field: 'labor',
  singular: true,
})

export const getLaborId = createSelector(
  getState,
  state => state.labor,
)
