import { createSelector } from 'reselect'

import { getViewer } from 'Store/Selectors/viewer'

import { transformToOnboarding } from 'Services/Entities/UserProfile'

export const getOnboardingProfile = createSelector(
  getViewer,
  ({ profile }) => transformToOnboarding(profile),
)

export default {}
