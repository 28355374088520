import { getEntities } from 'rapidux'

import { getData } from '../data'

export const getState = state => state.admin.roles

export const getRoles = getEntities(getState, getData, {
  type: 'roles',
  sorted: true,
})
