import { connect } from 'react-redux'

import { loadToken } from 'Store/Actions/admin/reports'

import ReportDownload from './ReportDownload'

export default connect(
  null,
  { onLoadToken: loadToken },
)(ReportDownload)
