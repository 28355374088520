import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { getClientById } from 'Store/Selectors/admin/clients'
import { activateClient, deactivateClient } from 'Store/Actions/admin/clients'
import ClientStatus from './ClientStatus'

export default connect(
  createStructuredSelector({
    client: (state, props) => getClientById(props.clientId)(state, props),
  }),
  {
    onActivateClient: activateClient,
    onDeactivateClient: deactivateClient,
  },
)(ClientStatus)
