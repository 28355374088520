import { connect } from 'react-redux'

import { changeTheme } from 'Store/Actions/ui'

import ThemeSwitch from './ThemeSwitch'

export default connect(
  null,
  {
    onChangeTheme: changeTheme,
  },
)(ThemeSwitch)
