import pickBy from 'lodash/pickBy'
import pick from 'lodash/pick'
import identity from 'lodash/identity'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'

import { addUSCode } from 'Services/Utils'
import has from 'lodash/has'
import map from 'lodash/map'
import { DateTime } from 'luxon'
import { getProjectById } from 'Store/Selectors/admin/projects'
import findKey from 'lodash/findKey'
import { PROJECT_TIME_ZONES } from 'Constants/types'

export const transformToRequest = values => ({
  attributes: pickBy(
    {
      ...pick(values, ['firstName', 'lastName', 'email']),
      phoneNumber: addUSCode(values.phoneNumber),
      kind: get(values, 'kind.value'),
    },
    identity,
  ),
  relationships: pickBy(
    {
      subcontractorProvider: isEqual(get(values, 'kind.value'), 'subcontractor')
        ? {
            data: {
              type: 'subcontractorProviders',
              id: get(values, 'subcontractorProvider.value'),
            },
          }
        : undefined,
    },
    identity,
  ),
})

export const transformToAddLaborTimeRequest = (getState, values) => {
  const FORMAT = 'LL/dd/yyyy'
  const DATE_FORMAT = 'LL/dd/y HH:mm'

  const projectId = get(values, 'project.value')
  const project = getProjectById(projectId)(getState())

  const date = DateTime.fromJSDate(get(values, 'date')).toFormat(FORMAT)
  const zoneName = get(project, 'projectTimeZone')
  const zone = findKey(PROJECT_TIME_ZONES, value => isEqual(value, zoneName))
  const startAt = get(values, 'startAt.value')
  const finishAt = get(values, 'finishAt.value')

  return {
    attributes: {
      ...pickBy(
        {
          ...pick(values, ['reason']),
        },

        identity,
      ),
      startAt: has(values, 'startAt')
        ? DateTime.fromFormat(`${date} ${startAt}`, DATE_FORMAT, {
            zone,
          }).toISO()
        : undefined,
      finishAt: finishAt
        ? DateTime.fromFormat(`${date} ${finishAt}`, DATE_FORMAT, {
            zone,
          }).toISO()
        : undefined,

      lunchDuration: get(values, 'lunchDuration.value'),
    },
    relationships: pickBy(
      {
        project: has(values, 'project')
          ? {
              data: {
                type: 'projects',
                id: projectId,
              },
            }
          : undefined,
        users: has(values, 'labors')
          ? {
              data: map(get(values, 'labors'), labor => ({
                type: 'users',
                id: labor.value,
              })),
            }
          : undefined,
        costCode: has(values, 'costCode')
          ? {
              data: {
                type: 'costCode',
                id: get(values, 'costCode.value'),
              },
            }
          : undefined,
      },
      identity,
    ),
  }
}

export default {}
