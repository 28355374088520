import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { compose } from 'recompose'

import { withAppContext } from 'Services/Context'

import {
  createPhoneNumber,
  sendVerificationCode,
  verifyPhone,
} from 'Store/Actions/admin/labors'

import ChangePhoneNumber from './ChangePhoneNumber'

export default compose(
  withRouter,
  withAppContext,
  connect(
    null,
    {
      onCreatePhoneNumber: createPhoneNumber,
      onSendVerificationCode: sendVerificationCode,
      onVerifyPhone: verifyPhone,
    },
  ),
)(ChangePhoneNumber)
