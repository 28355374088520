import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Flex, Box } from 'rebass'
import { Form } from 'react-final-form'
import validate from 'validate.js'
import { toast } from 'react-toastify'

import {
  presenceFieldConstraint,
  emailConstraint,
  phoneNumberConstraint,
} from 'Constants/constraints'
import { PHONE_MASK } from 'Constants/masks'
import { addUSCode } from 'Services/Utils'
import { Forms, Toast } from 'Components/Blocks'
import { Title, Button, Modal } from 'Components/UI'

import { Error } from '../styles'

class CreateClient extends PureComponent {
  state = { error: null }

  validate = values =>
    validate(values, {
      ...emailConstraint('email', false),
      ...presenceFieldConstraint('name'),
      ...phoneNumberConstraint('phoneNumber'),
    })

  handleCreateAdmin = async values => {
    const { onCreateClient, onCallback } = this.props

    this.setState({ error: null })

    const result = await onCreateClient({
      ...values,
      phoneNumber: addUSCode(values.phoneNumber),
    })

    if (result.ok) {
      toast.success(<Toast heading="Client created" type="success" />)
      onCallback()
    } else {
      this.setState({
        error: 'Something goes wrong. Please try again later...',
      })
    }
  }

  handleCallback = () => {
    const { onCallback } = this.props
    this.setState({ error: null })
    onCallback()
  }

  renderForm = ({ handleSubmit, invalid }) => {
    const { error } = this.state
    const { onCallback } = this.props

    return (
      <Box as="form" p={4} width={675} onSubmit={handleSubmit}>
        <Title.H2 color="brownishGray" mb={4} mt={4} textAlign="center">
          Create New Client
        </Title.H2>

        <Flex>
          <Box pr={3} width={[1, 1 / 2, 1 / 2]}>
            <Forms.Input
              label="Name"
              name="name"
              placeholder="Enter Client Name"
            />

            <Forms.Input
              label="Phone Number"
              mask={PHONE_MASK}
              name="phoneNumber"
              placeholder="Enter Client Phone Number"
            />

            <Forms.Input
              label="Email"
              name="email"
              placeholder="Enter Client Email"
            />
          </Box>

          <Box pl={3} width={[1, 1 / 2, 1 / 2]}>
            <Forms.TextArea
              height={213}
              label="Notes"
              name="notes"
              placeholder="Put any comments related to this Client"
            />
          </Box>
        </Flex>

        {error && <Error>{error}</Error>}

        <Flex alignItems="center" justifyContent="flex-end" mt={4}>
          <Button default mr={2} onClick={onCallback}>
            Close
          </Button>

          <Button disabled={invalid} secondary type="submit">
            Create Client
          </Button>
        </Flex>
      </Box>
    )
  }

  render() {
    const { isOpen } = this.props

    return (
      <Modal isOpen={isOpen} onCallback={this.handleCallback}>
        <Modal.Close onClick={this.handleCallback} />
        <Form
          render={this.renderForm}
          validate={this.validate}
          onSubmit={this.handleCreateAdmin}
        />
      </Modal>
    )
  }
}

CreateClient.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCallback: PropTypes.func.isRequired,
  onCreateClient: PropTypes.func.isRequired,
}

export default CreateClient
