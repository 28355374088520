import { createFields, createReducerHandlers } from 'rapidux'

import { createReducer } from 'Store/utils'

import { LOG_OUT } from 'Store/Actions/viewer'
import { LOAD_US_STATES } from 'Store/Actions/onboarding/basic'

const initialState = {
  ...createFields('usStates'),
}
const handlers = {
  ...createReducerHandlers('usStates', LOAD_US_STATES, {
    withReplace: false,
  }),

  [LOG_OUT]: state => state.merge(initialState),
}

export default createReducer(initialState, handlers)
