import styled from 'styled-components'
import { themeGet } from 'styled-system'
import Geosuggest from 'react-geosuggest'

import { Field as InputField } from 'Components/UI/Input'

export const Input = styled(Geosuggest)`
  position: relative;

  input {
    ${InputField.componentStyle.rules}
  }

  .geosuggest__suggests-wrapper {
    position: absolute;
    width: 100%;
    background-color: white;
    z-index: 1;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);

    .geosuggest__suggests {
      list-style-type: none;
      margin: 0;
      padding: 0;

      .geosuggest__item {
        cursor: pointer;
        padding: ${themeGet('space.1')}px ${themeGet('space.2')}px;
      }

      &--hidden {
        max-height: 0;
        overflow: hidden;
        border-width: 0;
      }
    }
  }
`

export default {}
