import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Modal, Title, Avatar, Loader, NavLink } from 'Components/UI'
import { denormalize } from 'rapidux'
import normalize from 'json-api-normalizer'

import map from 'lodash/map'
import get from 'lodash/get'
import find from 'lodash/find'

import { getProfileFullName } from 'Services/Utils'
import { ADMIN_PATHS } from 'Constants/paths'
import {
  LaborListWrap,
  LaborList,
  ListItem,
  LaborInfo,
  LaborFullname,
} from './styles'

class LaborsCheckins extends PureComponent {
  state = {
    isLoaded: false,
    checkinsLabors: [],
    laborsRoles: [],
  }

  async componentDidMount() {
    const { projectId, onLoadCheckins } = this.props
    const result = await onLoadCheckins({
      paged: true,
      size: 9999,
      filters: {
        projectId: { eq: projectId },
        state: { eq: 'active' },
      },
    })
    const normalizedData = normalize(result.payload.data, {
      endpoint: '/',
      camelizeKeys: true,
    })

    const denormalizedLabors = denormalize(normalizedData, 'labors') || []
    const denormalizedRoles =
      denormalize(normalizedData, 'projectUserRoles') || []

    if (result) {
      this.setState({
        isLoaded: true,
        checkinsLabors: denormalizedLabors,
        laborsRoles: denormalizedRoles,
      })
    }
  }

  getLaborFullName = labor => {
    return getProfileFullName(
      get(labor, 'profile.firstName'),
      get(labor, 'profile.lastName'),
    )
  }

  getLaborRole = labor => {
    const { laborsRoles } = this.state
    const laborRole = find(laborsRoles, role => role.user.id === labor.id)

    return get(laborRole, 'role.name', '')
  }

  renderLabors = () => {
    const { checkinsLabors } = this.state

    return map(checkinsLabors, labor => {
      const photo = get(labor, 'profile.personalPhoto.content.thumb.url')
      return (
        <ListItem key={labor.id}>
          <LaborInfo width={1 / 3}>
            <Avatar image={photo} size={30} />
            <NavLink to={ADMIN_PATHS.LABOR_DETAILS(labor.id)}>
              <LaborFullname>{this.getLaborFullName(labor)}</LaborFullname>
            </NavLink>
          </LaborInfo>
          <LaborInfo justifyContent="center" width={75}>
            {labor.id}
          </LaborInfo>
          <LaborInfo justifyContent="flex-end" width={1 / 3}>
            {this.getLaborRole(labor)}
          </LaborInfo>
        </ListItem>
      )
    })
  }

  render() {
    const { isOpen, onCloseModal } = this.props
    const { isLoaded } = this.state

    if (!isLoaded) return <Loader />

    return (
      <Modal isOpen={isOpen} onCallback={onCloseModal}>
        <Modal.Close onClick={onCloseModal} />
        <Title.H2 color="brownishGray" mb={4} mt={1} textAlign="center">
          Currently checked-in:
        </Title.H2>
        <LaborListWrap>
          <LaborList>{this.renderLabors()}</LaborList>
        </LaborListWrap>
      </Modal>
    )
  }
}

LaborsCheckins.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  projectId: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onLoadCheckins: PropTypes.func.isRequired,
}

export default LaborsCheckins
