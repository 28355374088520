import { combineReducers } from 'redux'

import admins from './admins'
import clients from './clients'
import labors from './labors'
import invitations from './invitations'
import projects from './projects'
import roles from './roles'
import checkins from './checkins'
import subcontractorProviders from './subcontractor-providers'

export default combineReducers({
  admins,
  clients,
  labors,
  invitations,
  projects,
  roles,
  checkins,
  subcontractorProviders,
})
