import merge from 'lodash/merge'
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'

import { createAsyncAction } from 'Store/utils'
import apiCall from 'Services/Api'
import { transformToAddLaborTimeRequest } from 'Services/Entities/Labor'

export const LOAD_CHECKINS = createAsyncAction('admin/checkins/LOAD')
export const ADD_LABOR_CHECKIN = createAsyncAction('admin/checkins/labor/ADD')

const checkinsIncludes = [
  'user',
  'user.profile',
  'user.profile.personalPhoto',
  'costCode',
  'role',
  'project.projectUserRoles',
  'project.projectUserRoles',
  'project.projectUserRoles.role',
  'checkinsChanges',
]

export const loadCheckins = ({
  paged = false,
  number = 1,
  size = 10,
  sort,
  filters,
} = {}) => (dispatch, getState) => {
  const params = {
    endpoint: '/checkins',
    types: LOAD_CHECKINS,
    query: {
      include: checkinsIncludes.join(),
    },
  }

  if (paged) {
    merge(params, {
      paged,
      query: pickBy(
        {
          include: checkinsIncludes.join(),
          page: {
            number,
            size,
          },
          filter: filters,
          sort,
        },
        identity,
      ),
      payload: {
        paged: {
          number,
          size,
        },
      },
    })
  }

  return apiCall(params)(dispatch, getState)
}

export const loadProjectCheckins = ({
  paged = false,
  number = 1,
  size = 10,
  sort,
  filters,
} = {}) => (dispatch, getState) => {
  const params = {
    endpoint: '/checkins',
    types: LOAD_CHECKINS,
    query: {
      include: checkinsIncludes.join(),
    },
    needsNormalization: false,
  }

  if (paged) {
    merge(params, {
      paged,
      query: pickBy(
        {
          include: checkinsIncludes.join(),
          page: {
            number,
            size,
          },
          filter: filters,
          sort,
        },
        identity,
      ),
      payload: {
        paged: {
          number,
          size,
        },
      },
    })
  }

  return apiCall(params)(dispatch, getState)
}

export const addLaborCheckin = values => (dispatch, getState) => {
  const data = transformToAddLaborTimeRequest(getState, values)

  return apiCall({
    method: 'POST',
    endpoint: '/admin/checkins',
    types: ADD_LABOR_CHECKIN,
    query: {
      include: checkinsIncludes.join(),
      data: {
        type: 'checkins',
        ...data,
      },
    },
  })(dispatch, getState)
}
