import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { Box } from 'rebass'

import map from 'lodash/map'
import findIndex from 'lodash/findIndex'
import isEqual from 'lodash/isEqual'
import filter from 'lodash/filter'

import { Select } from 'Components/Blocks/Forms'
import { getLaborsIds } from 'Services/Entities/Project'

import Role from './Role'

import { Container } from './styles'

const entityToOption = entity => ({
  value: entity.id,
  label: entity.name,
  labors: [],
})

class FieldRenderer extends PureComponent {
  componentDidMount() {
    const { kind, onLoadRoles } = this.props

    if (kind) {
      onLoadRoles()
    }
  }

  componentDidUpdate(prev) {
    const { kind, onLoadRoles } = this.props

    if (!isEqual(prev.kind, kind)) {
      onLoadRoles()
    }
  }

  get roles() {
    const { roles } = this.props
    const activeRoles = filter(roles.entities, role => role.state === 'active')
    return map(activeRoles, role => ({
      ...role,
      name: isEqual(role.name, 'Supervisor') ? 'Field Supervisor' : role.name,
    }))
  }

  handleChangeRoles = (values, { action }) => {
    const { input } = this.props

    switch (action) {
      case 'select-option':
      case 'remove-value':
        return input.onChange(values)
      default:
        return null
    }
  }

  handleChangeLabors = (role, labors) => {
    const { input } = this.props

    const roleIndex = findIndex(
      input.value,
      entity => entity.value === role.value,
    )

    input.value[roleIndex].labors = labors

    input.onChange([...input.value])
  }

  render() {
    const { input, onSearchLabors } = this.props
    const { roles, disabled } = this.props

    return (
      <Container>
        <Fragment>
          <Box>
            <Select
              components={{
                ClearIndicator: Select.emptyComponent,
              }}
              label="Project Roles*"
              name="roles"
              options={map(this.roles, entityToOption)}
              placeholder="Choose Roles"
              selectProps={{
                isMulti: true,
                isDisabled: disabled,
                isLoading: roles.isLoading,
                onChange: this.handleChangeRoles,
              }}
              value={input.value}
              width={1}
            />
          </Box>
          <Box>
            {map(input.value, value => {
              return (
                <Role
                  key={value.value}
                  laborsIds={getLaborsIds(input.value)}
                  role={value}
                  onChange={this.handleChangeLabors}
                  onSearchLabors={onSearchLabors}
                />
              )
            })}
          </Box>
        </Fragment>
      </Container>
    )
  }
}

FieldRenderer.defaultProps = { kind: null }

FieldRenderer.propTypes = {
  disabled: PropTypes.bool.isRequired,
  input: PropTypes.object.isRequired,
  kind: PropTypes.object,
  roles: PropTypes.object.isRequired,
  onLoadRoles: PropTypes.func.isRequired,
  onSearchLabors: PropTypes.func.isRequired,
}

const RolesSelector = ({ ...rest }) => (
  <Field component={FieldRenderer} {...rest} />
)

RolesSelector.defaultProps = {
  className: '',
}

RolesSelector.propTypes = {
  className: PropTypes.string,
}
export default RolesSelector
