import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { searchClients } from 'Store/Actions/admin/clients'
import { getClients } from 'Store/Selectors/admin/clients'
import ClientsSelector from './ClientsSelector'

export default connect(
  createStructuredSelector({
    clients: getClients,
  }),
  { onSearchClients: searchClients },
)(ClientsSelector)
