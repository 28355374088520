import get from 'lodash/get'

import { Public, Root, App, Auth, Onboarding, Admin } from 'Containers/Layout'
import {
  ROOT_PATH,
  APP_ROOT,
  ONBOARDING_ROOT,
  ADMIN_ROOT,
  AUTH_ROOT,
} from 'Constants/paths'
import { USER_TYPES } from 'Constants/types'

import publicRoutes from './public'
import authRoutes from './auth'
import adminRoutes from './admin'
import adminOnboardingRoutes from './onboardingAdmin'
import appRoutes from './app'
import onboardingRoutes from './onboarding'

const routesForType = viewer => ({
  [USER_TYPES.superAdmins]: [
    {
      path: ADMIN_ROOT,
      component: Admin,
      routes: adminRoutes(viewer),
    },
  ],
  [USER_TYPES.admins]: [
    {
      path: ADMIN_ROOT,
      component: Admin,
      routes: adminRoutes(viewer),
    },
  ],
  [USER_TYPES.onboardingAdmins]: [
    {
      path: ADMIN_ROOT,
      component: Admin,
      routes: adminOnboardingRoutes(viewer),
    },
  ],
  [USER_TYPES.user]: [
    {
      path: APP_ROOT,
      component: App,
      routes: appRoutes(viewer),
    },
  ],
})

const routes = viewer => {
  let viewerRoutes = []

  if (viewer.isAuthenticated) {
    viewerRoutes = viewer.isOnboarded
      ? get(routesForType(viewer), viewer.type, [])
      : [
          {
            path: ONBOARDING_ROOT,
            component: Onboarding,
            routes: onboardingRoutes(viewer),
          },
        ]
  }

  const allRoutes = [
    ...viewerRoutes,
    {
      path: AUTH_ROOT,
      component: Auth,
      routes: authRoutes(viewer),
    },
    {
      path: ROOT_PATH,
      component: Public,
      routes: publicRoutes(viewer),
    },
  ]

  return [
    {
      component: Root,
      routes: allRoutes,
    },
  ]
}

export default routes
