import { NotFound } from 'Containers/Layout'

import { ROOT_PATH, PUBLIC_PATHS } from 'Constants/paths'

import * as PublicPages from 'Containers/Pages/Public'

const routes = () => [
  /* Public */
  {
    path: ROOT_PATH,
    exact: true,
    component: PublicPages.Landing,
  },
  {
    path: PUBLIC_PATHS.PRIVACY_POLICY,
    exact: true,
    component: PublicPages.PrivacyPolicy,
  },
  {
    path: PUBLIC_PATHS.SUPPORT,
    exact: true,
    component: PublicPages.Support,
  },
  { component: NotFound },
]

export default routes
