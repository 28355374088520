import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { searchLabors } from 'Store/Actions/admin/labors'
import { getLabors } from 'Store/Selectors/admin/labors'
import ReportLaborSelector from './ReportLaborSelector'

export default connect(
  createStructuredSelector({ labors: getLabors }),
  { onSearchLabors: searchLabors },
)(ReportLaborSelector)
