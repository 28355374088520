import { USER_TYPES } from './types'

const access = {
  [USER_TYPES.superAdmins]: {
    can: {
      create: ['all'],
      read: ['all'],
      update: ['all'],
      delete: ['all'],
    },
  },
  [USER_TYPES.admins]: {
    can: {
      read: ['all'],
    },
    cannot: {
      read: ['admins'],
    },
  },
  [USER_TYPES.onboardingAdmins]: {
    cannot: {
      read: ['all'],
    },
    can: {
      read: ['labors'],
    },
  },
}

export default access
