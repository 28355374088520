export const USER_TYPES = {
  superAdmins: 'superAdmins',
  admins: 'admins',
  onboardingAdmins: 'onboardingAdmins',
  users: 'users',
}

export const HUMAN_USER_TYPES = {
  [USER_TYPES.superAdmins]: 'Super Admin',
  [USER_TYPES.admins]: 'Admin',
  [USER_TYPES.users]: 'User',
  [USER_TYPES.onboardingAdmins]: 'Onboarding Admin',
}

export const ADMIN_FORM_USER_TYPES = {
  [USER_TYPES.admins]: 'admin',
  [USER_TYPES.onboardingAdmins]: 'onboarding_admin',
}

export const PROJECT_KINDS = {
  commercial_jobs: 'commercial_jobs',
  emergency_services: 'emergency_services',
}
export const PROJECT_KIND_LABELS = {
  [PROJECT_KINDS.commercial_jobs]: 'Fixed Price',
  [PROJECT_KINDS.emergency_services]: 'T&M',
}

export const PROJECT_SIGNATURE_LABELS = {
  digital: 'Digital Signature',
  handwritten: 'Handwritten Signature',
}

export const PROJECT_OT_RULE_LABELS = {
  '40hrs_week': '40 hrs/week',
  '40hrs week': '40 hrs/week',
  '40hrs_week_8hrs_day': '40 hrs/week + 8 hrs/day',
}

export const PROJECT_TIME_ZONES = {
  'America/Halifax': 'Canada/Atlantic',
  'America/New_York': 'US/Eastern',
  'America/Chicago': 'US/Central',
  'America/Denver': 'US/Mountain',
  'America/Los_Angeles': 'US/Pacific',
  'America/Anchorage': 'US/Alaska',
  'Pacific/Honolulu': 'US/Hawaii',
}

export const PROJECT_TIME_ZONE_LABELS = {
  [PROJECT_TIME_ZONES['America/Halifax']]: 'Atlantic Time',
  [PROJECT_TIME_ZONES['America/New_York']]: 'Eastern Time',
  [PROJECT_TIME_ZONES['America/Chicago']]: 'Central Time',
  [PROJECT_TIME_ZONES['America/Denver']]: 'Mountain Time',
  [PROJECT_TIME_ZONES['America/Los_Angeles']]: 'Pacific Time',
  [PROJECT_TIME_ZONES['America/Anchorage']]: 'Alaska Time',
  [PROJECT_TIME_ZONES['Pacific/Honolulu']]: 'Hawaii Time',
}

export const DAY_NAMES = {
  sunday: 'sunday',
  monday: 'monday',
  tuesday: 'tuesday',
  wednesday: 'wednesday',
  thursday: 'thursday',
  friday: 'friday',
  saturday: 'saturday',
}

export const PROJECT_WEEKEND_LABELS = {
  [DAY_NAMES.sunday]: 'Sunday',
  [DAY_NAMES.monday]: 'Monday',
  [DAY_NAMES.tuesday]: 'Tuesday',
  [DAY_NAMES.wednesday]: 'Wednesday',
  [DAY_NAMES.thursday]: 'Thursday',
  [DAY_NAMES.friday]: 'Friday',
  [DAY_NAMES.saturday]: 'Saturday',
}

export const DAY_INDEXES = {
  [DAY_NAMES.sunday]: 0,
  [DAY_NAMES.monday]: 1,
  [DAY_NAMES.tuesday]: 2,
  [DAY_NAMES.wednesday]: 3,
  [DAY_NAMES.thursday]: 4,
  [DAY_NAMES.friday]: 5,
  [DAY_NAMES.saturday]: 6,
}

export const REPORT_TYPES = {
  laborTimeCard: 'laborTimeCard',
  employeeWeeklyPayrollReport: 'employeeWeeklyPayrollReport',
  subcontractorWeeklyReport: 'subcontractorWeeklyReport',
  subcontractorCostCodeReport: 'subcontractorCostCodeReport',
  projectWeeklyReport: 'projectWeeklyReport',
  projectDailyReportInternal: 'projectDailyReportInternal',
  projectDailyReportExternal: 'projectDailyReportExternal',
  projectDailyFormanNotes: 'projectDailyFormanNotes',
  employeeHoursReport: 'employeeHoursReport',
}

export const REPORT_LABELS = {
  [REPORT_TYPES.laborTimeCard]: 'Labor Time Card',
  [REPORT_TYPES.employeeWeeklyPayrollReport]: 'Employee Weekly Payroll Report',
  [REPORT_TYPES.subcontractorWeeklyReport]: 'Subcontractor Weekly Report',
  [REPORT_TYPES.subcontractorCostCodeReport]: 'Subcontractor Cost Code Report',
  [REPORT_TYPES.projectWeeklyReport]: 'Project Weekly Report',
  [REPORT_TYPES.projectDailyReportInternal]: 'Project Daily Report Internal',
  [REPORT_TYPES.projectDailyReportExternal]: 'Project Daily Report External',
  [REPORT_TYPES.projectDailyFormanNotes]: 'Project Daily Forman Notes',
  [REPORT_TYPES.employeeHoursReport]: 'Employee Hours Report',
}

export const WEATHER_ROWS = {
  precipitations: 'precipitations',
  temperatureMax: 'temperatureMax',
  temperatureMin: 'temperatureMin',
}

export const WEATHER_ROW_LABELS = {
  [WEATHER_ROWS.precipitations]: 'Precep. (In.)',
  [WEATHER_ROWS.temperatureMax]: 'High Temp',
  [WEATHER_ROWS.temperatureMin]: 'Low Temp',
}

export const LABOR_KIND_TYPES = {
  subcontractor: 'subcontractor',
  direct: 'direct',
}
