import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'
import merge from 'lodash/merge'

import { createAsyncAction } from 'Store/utils'
import { getLaborId } from 'Store/Selectors/admin/labors'
import apiCall from 'Services/Api'
import { transformToRequest as transformProfileToRequest } from 'Services/Entities/UserProfile'
import { transformToRequest as transformLaborToRequest } from 'Services/Entities/Labor'
import { PROFILE_INCLUDES } from 'Constants/includes'

export const LOAD_LABORS = createAsyncAction('admin/labors/LOAD')
export const LOAD_LABOR = createAsyncAction('admin/labor/LOAD')
export const SEARCH_LABORS = createAsyncAction('admin/labors/SEARCH')
export const INVITE_LABOR = createAsyncAction('admin/labors/INVITE')
export const CREATE_LABOR = createAsyncAction('admin/labors/CREATE')
export const ACTIVATE_LABOR = createAsyncAction('admin/labor/ACTIVATE')
export const DEACTIVATE_LABOR = createAsyncAction('admin/labor/DEACTIVATE')
export const UPDATE_LABOR = createAsyncAction('admin/labor/UPDATE')
export const CREATE_PHONE_NUMBER = createAsyncAction(
  'admin/labor/phone/CREATE_PHONE_NUMBER',
)
export const SEND_VERIFICATION_CODE = createAsyncAction(
  'admin/labor/phone/SEND_VERIFICATION_CODE',
)
export const VERIFY_PHONE = createAsyncAction('admin/labor/phone/VERIFY_PHONE')
export const DELETE_LABOR = createAsyncAction('admin/labor/DELETE')
export const SEND_INVITE_TO_LABOR = createAsyncAction('admin/labor/INVITE/SEND')
export const SWITCH_LABOR_KIND = createAsyncAction('admin/labor/KIND/UPDATE')

export const laborIncludes = [
  'emailCredential',
  'profile',
  ...PROFILE_INCLUDES.map(fieldName => `profile.${fieldName}`),
  'subcontractorProvider',
]

export const searchLabors = (searchQuery, filters) =>
  apiCall({
    endpoint: '/admin/labors',
    types: SEARCH_LABORS,
    query: {
      filter: merge(filters, {
        profile: {
          fullName: {
            ilike: `%${searchQuery}%`,
          },
        },
      }),
      page: {
        number: 1,
        size: 20,
      },
      include: laborIncludes.join(),
    },
  })

export const loadLabors = ({
  paged = false,
  number = 1,
  size = 10,
  sort,
  filters,
} = {}) => (dispatch, getState) => {
  const params = {
    endpoint: '/admin/labors',
    types: LOAD_LABORS,
    query: {
      include: [...laborIncludes, 'employeePackage'].join(),
    },
  }

  if (paged) {
    merge(params, {
      paged,
      query: pickBy(
        {
          include: [...laborIncludes, 'employeePackage'].join(),
          page: {
            number,
            size,
          },
          filter: filters,
          sort,
        },
        identity,
      ),
      payload: {
        paged: {
          number,
          size,
        },
      },
    })
  }

  return apiCall(params)(dispatch, getState)
}

export const inviteLabor = values =>
  apiCall({
    method: 'POST',
    endpoint: '/admin/labors/invite',
    query: {
      include: laborIncludes.join(),
      data: {
        type: 'laborInvitations',
        ...transformLaborToRequest(values),
      },
    },
    types: INVITE_LABOR,
  })

export const sendInvite = laborId =>
  apiCall({
    method: 'POST',
    endpoint: `/admin/labors/${laborId}/invite`,
    types: SEND_INVITE_TO_LABOR,
    query: {
      include: [],
    },
  })

export const createLabor = values =>
  apiCall({
    method: 'POST',
    endpoint: '/admin/labors',
    types: CREATE_LABOR,
    query: {
      include: laborIncludes.join(),
      data: {
        type: 'labors',
        ...transformLaborToRequest(values),
      },
    },
  })

export const loadLabor = laborId =>
  apiCall({
    endpoint: `/admin/labors/${laborId}`,
    types: LOAD_LABOR,
    query: {
      include: [...laborIncludes, 'qrcode', 'idCard', 'employeePackage'].join(),
    },
  })

export const activateLabor = laborId =>
  apiCall({
    method: 'POST',
    endpoint: `/admin/labors/${laborId}/activation`,
    types: ACTIVATE_LABOR,
    query: {
      include: [...laborIncludes, 'qrcode', 'idCard', 'employeePackage'].join(),
    },
  })

export const deactivateLabor = laborId =>
  apiCall({
    method: 'DELETE',
    endpoint: `/admin/labors/${laborId}/activation`,
    types: DEACTIVATE_LABOR,
    query: {
      include: [...laborIncludes, 'qrcore', 'idCard', 'employeePackage'].join(),
    },
  })

export const updateLabor = values => (dispatch, getState) => {
  const laborId = getLaborId(getState())

  return dispatch(
    apiCall({
      method: 'PATCH',
      endpoint: `/admin/labors/${laborId}/profile`,
      types: UPDATE_LABOR,
      query: {
        include: PROFILE_INCLUDES.join(),
        data: {
          type: 'profiles',
          ...transformProfileToRequest(values),
        },
      },
    }),
  )
}

export const createPhoneNumber = ({ laborId, phoneNumber }) =>
  apiCall({
    method: 'POST',
    endpoint: `/admin/labors/${laborId}/profile/phone_numbers`,
    types: CREATE_PHONE_NUMBER,
    query: {
      include: 'verifiedPrimaryPhoneNumber,pendingPrimaryPhoneNumber',
      data: {
        type: 'phoneNumber',
        attributes: {
          phoneNumber,
          countryCode: '+1',
          kind: 'primary',
        },
      },
    },
  })

export const sendVerificationCode = ({ laborId, phoneNumberId }) =>
  apiCall({
    method: 'POST',
    endpoint: `/admin/labors/${laborId}/profile/phone_numbers/${phoneNumberId}/send_code`,
    types: SEND_VERIFICATION_CODE,
    query: {},
  })

export const verifyPhone = ({ laborId, phoneNumberId, verificationCode }) =>
  apiCall({
    method: 'POST',
    endpoint: `/admin/labors/${laborId}/profile/phone_numbers/${phoneNumberId}/verify`,
    types: VERIFY_PHONE,
    query: {
      include: laborIncludes.join(),
      data: {
        type: 'phoneNumbers',
        attributes: { verificationCode },
      },
    },
  })

export const deleteLabor = laborId =>
  apiCall({
    method: 'DELETE',
    endpoint: `/admin/labors/${laborId}`,
    types: DELETE_LABOR,
    payload: {
      deletedId: laborId,
    },
  })

export const switchLaborToDirect = ({
  laborId,
  kind,
  subcontractorProviderId,
}) => {
  let data = {
    type: 'labors',
    attributes: pickBy({ kind }, identity),
  }

  if (subcontractorProviderId)
    data = {
      ...data,
      relationships: {
        subcontractorProvider: {
          data: {
            type: 'subcontractorProviders',
            id: subcontractorProviderId,
          },
        },
      },
    }

  return apiCall({
    method: 'PUT',
    endpoint: `/admin/labors/${laborId}/labor_kind`,
    types: SWITCH_LABOR_KIND,
    query: {
      data,
      include: [...laborIncludes].join(),
    },
  })
}
