import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { mapToTheme } from 'styled-map'
import { Flex, Box } from 'rebass'

export const Container = styled(Flex)`
  width: calc(100vw - ${mapToTheme('sizes.sidebarWidth')});
  height: ${themeGet('sizes.header')};
  flex-direction: row;
  position: fixed;
  left: ${mapToTheme('sizes.sidebarWidth')};
  z-index: 300;
  transition: height 0.25s ease-in-out;
  order: 1;
  flex: initial;
  background: ${themeGet('colors.white')};
  flex-shrink: 0;

  @media screen and (max-width: ${themeGet('breakpoints.0')}) {
    background: none;
    width: ${themeGet('sizes.header')};
    position: absolute;
    top: 0;
    right: 0;
    left: auto;
    display: block;
  }
`

export const Bar = styled(Flex)`
  align-items: center;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  flex-shrink: 0;

  @media screen and (max-width: ${themeGet('breakpoints.0')}) {
    display: none;
  }
`

export const Menu = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const User = styled(Flex)`
  flex-shrink: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 24px;
  cursor: pointer;
`

export const UserMobile = styled(Box)`
  position: absolute;
  right: 16px;
  top: 32px;
  display: none;

  @media screen and (max-width: ${themeGet('breakpoints.0')}) {
    display: block;
  }
`
