import React from 'react'
import PropTypes from 'prop-types'

import { ONBOARDING_PATHS } from 'Constants/paths'

import { STEP_NAMES } from 'Constants/onboarding'

import {
  informationOutlineGlyph,
  onboardingDocGlyph,
  onboardingPhoneGlyp,
  logoGlyph,
  onboardingBadgeGlyph,
} from 'Assets/Svg'

import { Icon } from 'Components/UI'

import {
  Container,
  Bar,
  Menu,
  StyledNav,
  Label,
  Top,
  Bottom,
  Logo,
} from './styles'

const Sidebar = ({ disabledSteps }) => (
  <Container>
    <Bar>
      <Menu>
        <Top>
          <Logo>
            <Icon glyph={logoGlyph} size={48} />
            Admin registration
          </Logo>
          <StyledNav
            disabled={disabledSteps.includes(STEP_NAMES.BASIC)}
            exact
            href={ONBOARDING_PATHS.BASIC}
            to={ONBOARDING_PATHS.BASIC}
          >
            <Icon
              fill="dodgerBlue"
              glyph={informationOutlineGlyph}
              size={18}
              stroke="dodgerBlue"
            />
            <Label>Basic Information</Label>
          </StyledNav>

          <StyledNav
            disabled={disabledSteps.includes(STEP_NAMES.PHOTO)}
            exact
            href={ONBOARDING_PATHS.PHOTO}
            to={ONBOARDING_PATHS.PHOTO}
          >
            <Icon glyph={onboardingDocGlyph} size={18} />
            <Label>Your Photo</Label>
          </StyledNav>

          <StyledNav
            disabled={disabledSteps.includes(STEP_NAMES.PHONE)}
            href={ONBOARDING_PATHS.PHONE}
            to={ONBOARDING_PATHS.PHONE}
          >
            <Icon glyph={onboardingPhoneGlyp} size={18} />
            <Label>Phone validation</Label>
          </StyledNav>

          <StyledNav
            href={ONBOARDING_PATHS.BADGE_AKNOWLEDGEMENT}
            to={ONBOARDING_PATHS.BADGE_AKNOWLEDGEMENT}
          >
            <Icon glyph={onboardingBadgeGlyph} size={18} />
            <Label>Identification Card Acknowledgement</Label>
          </StyledNav>
        </Top>
        <Bottom />
      </Menu>
    </Bar>
  </Container>
)

Sidebar.propTypes = {
  disabledSteps: PropTypes.arrayOf(PropTypes.number).isRequired,
}

export default Sidebar
