import React from 'react'

const Basic = React.lazy(() =>
  import('./Basic' /* webpackChunkName: "onboarding-basic" */),
)

// const Race = React.lazy(() =>
//   import('./Race' /* webpackChunkName: "onboarding-race" */),
// )
//
// const Military = React.lazy(() =>
//   import('./Military' /* webpackChunkName: "onboarding-military" */),
// )
//
// const Equal = React.lazy(() =>
//   import('./Equal' /* webpackChunkName: "onboarding-equal" */),
// )
//
// const Docs = React.lazy(() =>
//   import('./Docs' /* webpackChunkName: "onboarding-docs" */),
// )

const Photo = React.lazy(() =>
  import('./Photo' /* webpackChunkName: "onboarding-photo" */),
)

const Phone = React.lazy(() =>
  import('./Phone' /* webpackChunkName: "onboarding-phone" */),
)

const PhoneVerification = React.lazy(() =>
  import(
    './PhoneVerification' /* webpackChunkName: "onboarding-phone-verification" */
  ),
)

const BadgeAknowledgement = React.lazy(() =>
  import(
    './BadgeAknowledgement' /* webpackChunkName: "onboarding-phone-verification" */
  ),
)

export { Basic, Photo, Phone, PhoneVerification, BadgeAknowledgement }
