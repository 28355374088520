import merge from 'lodash/merge'
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'

import { createAsyncAction } from 'Store/utils'

import { transformToRequest } from 'Services/Entities/Project'

import apiCall from 'Services/Api'

export const LOAD_PROJECTS = createAsyncAction('admin/projects/LOAD')
export const LOAD_PROJECT = createAsyncAction('admin/projects/LOAD_ONE')
export const SEARCH_PROJECTS = createAsyncAction('admin/projects/SEARCH')
export const SEARCH_COST_CODES = createAsyncAction(
  'admin/projects/SEARCH_COST_CODES',
)
export const CREATE_COST_CODE = createAsyncAction(
  'admin/projects/CREATE_COST_CODE',
)
export const CREATE_PROJECT = createAsyncAction('admin/projects/CREATE')
export const UPDATE_PROJECT = createAsyncAction('admin/projects/UPDATE')
export const ACTIVATE_PROJECT = createAsyncAction('admin/projects/ACTIVATE')
export const DEACTIVATE_PROJECT = createAsyncAction('admin/projects/DEACTIVATE')

const projectIncludes = [
  'client',
  'roles',
  'costCodes',
  'projectUserRoles.user.profile.personalPhoto',
  'projectUserRoles.permissions',
  'supervisors',
  'activeCheckinsCount',
]

export const searchProjects = searchQuery =>
  apiCall({
    endpoint: '/projects',
    types: SEARCH_PROJECTS,
    query: {
      include: projectIncludes.join(),
      filter: {
        name: {
          ilike: `%${searchQuery}%`,
        },
      },
      page: {
        number: 1,
        size: 20,
      },
    },
  })

export const searchCostCodes = searchQuery =>
  apiCall({
    endpoint: '/cost_codes',
    types: SEARCH_COST_CODES,
    needsNormalization: false,
    query: {
      filter: {
        code: {
          like: `%${searchQuery}%`,
        },
      },
      page: {
        number: 1,
        size: 20,
      },
    },
  })

export const createCostCode = code =>
  apiCall({
    method: 'POST',
    endpoint: '/admin/cost_codes',
    needsNormalization: false,
    query: {
      data: {
        type: 'costCodes',
        attributes: { code },
      },
    },
  })

export const loadProjects = ({
  paged = false,
  number = 1,
  size = 10,
  sort,
  filters,
} = {}) => (dispatch, getState) => {
  const params = {
    endpoint: '/projects',
    types: LOAD_PROJECTS,
    query: {
      include: projectIncludes.join(),
    },
  }

  if (paged) {
    merge(params, {
      paged,
      query: pickBy(
        {
          include: projectIncludes.join(),
          page: {
            number,
            size,
          },
          filter: filters,
          sort,
        },
        identity,
      ),
      payload: {
        paged: {
          number,
          size,
        },
      },
    })
  }

  return apiCall(params)(dispatch, getState)
}

export const loadProject = projectId =>
  apiCall({
    endpoint: `/projects/${projectId}`,
    types: LOAD_PROJECT,
    query: {
      include: projectIncludes.join(),
    },
  })

export const createProject = values =>
  apiCall({
    method: 'POST',
    endpoint: '/projects',
    types: CREATE_PROJECT,
    query: {
      include: projectIncludes.join(),
      data: transformToRequest(values),
    },
  })

export const updateProject = (projectId, values) =>
  apiCall({
    method: 'PATCH',
    endpoint: `/projects/${projectId}`,
    types: UPDATE_PROJECT,
    query: {
      include: projectIncludes.join(),
      data: transformToRequest(values),
    },
  })

export const activateProject = projectId =>
  apiCall({
    method: 'POST',
    endpoint: `/projects/${projectId}/activation`,
    types: ACTIVATE_PROJECT,
    query: {
      include: projectIncludes.join(),
    },
  })

export const deactivateProject = projectId =>
  apiCall({
    method: 'DELETE',
    endpoint: `/projects/${projectId}/activation`,
    types: DEACTIVATE_PROJECT,
    query: {
      include: projectIncludes.join(),
    },
  })
