import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Avatar } from 'Components/UI'

import { Container, Bar, Menu, User, UserMobile } from './styles'

class Header extends Component {
  state = {}

  handleLogout = () => {
    const { onLogOut } = this.props
    onLogOut()
  }

  render() {
    return (
      <Container>
        <Bar>
          <div />
          <Menu>
            <User>
              <Avatar size={36} onClick={this.handleLogout} />
            </User>
          </Menu>
        </Bar>
        <UserMobile>
          <Avatar size={36} onClick={this.handleLogout} />
        </UserMobile>
      </Container>
    )
  }
}

Header.propTypes = {
  onLogOut: PropTypes.func.isRequired,
}

export default Header
