import get from 'lodash/get'
import { DateTime } from 'luxon'
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'

import { getSelectValue, addUSCode } from 'Services/Utils'
import { BIRTHDAY_DATE_FORMAT } from 'Constants/onboarding'

export const transformToOnboarding = profile =>
  pickBy(
    {
      ...profile,
      birthday: get(profile, 'birthday')
        ? DateTime.fromISO(profile.birthday).toFormat(BIRTHDAY_DATE_FORMAT)
        : undefined,
      gender: get(profile, 'gender')
        ? getSelectValue(profile.gender)
        : undefined,
      maritalStatus: get(profile, 'maritalStatus')
        ? getSelectValue(profile.maritalStatus)
        : undefined,
      driverLicenseState: get(profile, 'driverLicenseState')
        ? getSelectValue(
            get(profile, 'driverLicenseState.id'),
            get(profile, 'driverLicenseState.name'),
          )
        : undefined,
      usState: get(profile, 'usState')
        ? getSelectValue(
            get(profile, 'usState.id'),
            get(profile, 'usState.name'),
          )
        : undefined,
      ethnicity: get(profile, 'ethnicity.id'),
      isMilitaryServiceType: !!profile.militaryServiceType,
      militaryServiceType: get(profile, 'militaryServiceType.id'),
      driverLicensePhoto: get(profile, 'driverLicensePhoto.id'),
      personalPhoto: get(profile, 'personalPhoto.id'),
      socialSecurityCardPhoto: get(profile, 'socialSecurityCardPhoto.id'),
    },
    identity,
  )

export const transformToRequest = ({
  usState,
  driverLicenseState,
  ethnicity,
  isMilitaryServiceType,
  militaryServiceType,
  personalPhoto,
  socialSecurityCardPhoto,
  driverLicensePhoto,
  alternatePhoneNumber,
  emergencyContactPhoneNumber,
  gender,
  birthday,
  maritalStatus,
  ...attributes
}) => ({
  attributes: pickBy(
    {
      ...attributes,
      alternatePhoneNumber: alternatePhoneNumber
        ? addUSCode(alternatePhoneNumber)
        : undefined,
      emergencyContactPhoneNumber: emergencyContactPhoneNumber
        ? addUSCode(emergencyContactPhoneNumber)
        : undefined,
      birthday: birthday
        ? DateTime.fromFormat(birthday, 'LL/dd/y').toISO()
        : undefined,
      gender: gender ? get(gender, 'value') : undefined,
      maritalStatus: maritalStatus ? get(maritalStatus, 'value') : undefined,
    },
    identity,
  ),
  relationships: pickBy(
    {
      usState: usState
        ? { data: { type: 'usStates', id: get(usState, 'value') } }
        : undefined,
      driverLicenseState: driverLicenseState
        ? { data: { type: 'usStates', id: get(driverLicenseState, 'value') } }
        : undefined,
      ethnicity: ethnicity
        ? { data: { type: 'ethnicities', id: ethnicity } }
        : undefined,
      militaryServiceType: militaryServiceType
        ? {
            data: isMilitaryServiceType
              ? {
                  type: 'militaryServiceTypes',
                  id: militaryServiceType,
                }
              : null,
          }
        : undefined,
      personalPhoto: personalPhoto
        ? { data: { type: 'images', id: personalPhoto } }
        : undefined,
      socialSecurityCardPhoto: socialSecurityCardPhoto
        ? { data: { type: 'images', id: socialSecurityCardPhoto } }
        : undefined,
      driverLicensePhoto: driverLicensePhoto
        ? { data: { type: 'images', id: driverLicensePhoto } }
        : undefined,
    },
    identity,
  ),
})
