import { createSelector } from 'reselect'
import { getEntities, denormalize } from 'rapidux'

import { getData } from '../data'

export const getState = state => state.admin.subcontractorProviders

export const getSubcontractorProviders = getEntities(getState, getData, {
  type: 'subcontractorProviders',
  sorted: true,
})

export const getSubcontractorProvider = getEntities(getState, getData, {
  type: 'subcontractorProviders',
  field: 'subcontractorProvider',
  singular: true,
})

export const getSubcontractorProviderById = subcontractorProviderId =>
  createSelector(
    getData,
    data =>
      subcontractorProviderId
        ? denormalize(data, 'subcontractorProviders', subcontractorProviderId)
        : null,
  )

export const getSubcontractorProviderLabors = getEntities(getState, getData, {
  type: 'labors',
  field: 'subcontractorProviderLabors',
  sorted: true,
})
