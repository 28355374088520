import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Form } from 'react-final-form'
import { Flex, Box } from 'rebass'

import noop from 'lodash/noop'

import { getFilterForm } from 'Store/Selectors/tables'
import { Select, Input, DayPicker } from 'Components/Blocks/Forms'
import { magnifyGlyph } from 'Assets/Svg'
import FormStateToRedux from './FormStateToRedux'

const type = 'projects'

const ProjectsFilterForm = ({ initialValues }) => (
  <Form initialValues={initialValues} onSubmit={noop}>
    {({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <FormStateToRedux type={type} />

        <Flex alignItems="center" justifyContent="space-between">
          <Flex width={3 / 4}>
            <Box mr={[2, 3, 3, 4]} width={1 / 4}>
              <Select
                height={30}
                label="Project Type"
                name="kind"
                options={[
                  { value: 'all', label: 'Show All' },
                  { value: 'commercial_jobs', label: 'Commercial Jobs' },
                  { value: 'emergency_services', label: 'Emergency Services' },
                ]}
                placeholder="Show All"
                selectProps={{
                  isSearchable: false,
                }}
              />
            </Box>

            <Box mr={[2, 3, 3, 4]} width={1 / 4}>
              <Select
                height={30}
                label="Status"
                name="status"
                options={[
                  { value: 'all', label: 'Show All' },
                  { value: 'active', label: 'Active' },
                  { value: 'deactivated', label: 'Deactivated' },
                ]}
                placeholder="Show All"
                selectProps={{
                  isSearchable: false,
                }}
              />
            </Box>

            <Box mr={[2, 3, 3, 4]} width={2 / 4}>
              <DayPicker label="Range" name="date_range" ranged />
            </Box>
          </Flex>

          <Box ml={[2, 3, 3, 4]} width={1 / 4}>
            <Input
              icon={magnifyGlyph}
              iconFill="dodgerBlue"
              iconSize={20}
              label="&nbsp;"
              name="search"
              placeholder=""
            />
          </Box>
        </Flex>
      </form>
    )}
  </Form>
)

ProjectsFilterForm.propTypes = { initialValues: PropTypes.object.isRequired }

export default connect(
  createStructuredSelector({
    initialValues: (state, props) => getFilterForm(type)(state, props),
  }),
)(ProjectsFilterForm)
