import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import { Tip, Icon, Text } from 'Components/UI'

import { checkMarkButtonGlyph } from 'Assets/Svg'

import { Container, TipWrapper, Content } from './styles'

const BadgeAknowledgementInfo = ({ completedDate }) => {
  const getFormattedDate = () => {
    const date = DateTime.fromISO(completedDate)

    return date.toFormat('LL/dd/yyyy')
  }
  return (
    <Container>
      <TipWrapper>
        <Tip>Acknowledged within Onboarding.</Tip>
      </TipWrapper>
      <Content>
        <Icon fill="#449aff" glyph={checkMarkButtonGlyph} size={32} />
        <Text
          color="brownishGray"
          fontWeight="bold"
          mt={1}
          textAlign="center"
          width="100%"
        >
          Identification Card Acknowledgement submitted on <br />
          {getFormattedDate()}.
        </Text>
      </Content>
    </Container>
  )
}

BadgeAknowledgementInfo.propTypes = {
  completedDate: PropTypes.string.isRequired,
}
export default BadgeAknowledgementInfo
