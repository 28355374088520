import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Flex, Text } from 'rebass'

import { closeGlyph } from 'Assets/Svg'

import { Icon } from 'Components/UI'

export const Container = styled(Flex).attrs({
  mr: 3,
  mb: 3,
  fontSize: 3,
})`
  align-items: center;
`

export const Name = styled(Text).attrs({
  ml: 2,
})`
  color: ${themeGet('colors.primary')};
`

export const RemoveIcon = styled(Icon).attrs({
  size: 14,
  glyph: closeGlyph,
  ml: 2,
})`
  cursor: pointer;
  fill: ${themeGet('colors.brownGray')};
`
