import { createSelector } from 'reselect'
import { getEntities, denormalize } from 'rapidux'

import { getData } from '../data'

export const getState = state => state.admin.projects

export const getProjects = getEntities(getState, getData, {
  type: 'projects',
  sorted: true,
})

export const getProject = getEntities(getState, getData, {
  type: 'projects',
  field: 'project',
  singular: true,
})

export const getProjectById = projectId =>
  createSelector(
    getData,
    data => (projectId ? denormalize(data, 'projects', projectId) : null),
  )
