import { createFields, createLoadHandler } from 'rapidux'

import get from 'lodash/get'
import forEach from 'lodash/forEach'

import { createReducer } from 'Store/utils'

import { LOG_OUT } from 'Store/Actions/viewer'
import {
  LOAD_ADMINS,
  LOAD_ADMIN,
  CREATE_ADMIN,
  DELETE_ADMIN,
} from 'Store/Actions/admin/admins'

const initialState = {
  ...createFields('admins'),
  ...createFields('admins', 'admin', true),
  paged: {},
}
const handlers = {
  [CREATE_ADMIN.SUCCESS]: createLoadHandler('admins'),
  [LOAD_ADMINS.REQUEST]: state => {
    return state.merge({
      isLoaded: false,
      isLoading: true,
    })
  },
  [LOAD_ADMINS.FAILURE]: state => {
    return state.merge({
      isLoaded: false,
      isLoading: false,
    })
  },
  [LOAD_ADMINS.SUCCESS]: (state, { payload, paged }) => {
    const admins = {}

    forEach(Object.keys(get(payload, 'data.admins', [])), id => {
      admins[id] = 'admins'
    })

    forEach(Object.keys(get(payload, 'data.onboardingAdmins', [])), id => {
      admins[id] = 'onboardingAdmins'
    })

    return state.merge({
      admins,
      paged,
      isLoaded: true,
      isLoading: false,
    })
  },
  [LOAD_ADMIN.REQUEST]: state => {
    return state.merge({
      isLoadedAdmin: false,
      isLoadingAdmin: true,
    })
  },
  [LOAD_ADMIN.FAILURE]: state => {
    return state.merge({
      isLoadedAdmin: false,
      isLoadingAdmin: false,
    })
  },
  [LOAD_ADMIN.SUCCESS]: (state, { payload, paged }) => {
    const admin = {}

    forEach(Object.keys(get(payload, 'data.admins', [])), id => {
      admin[id] = 'admins'
    })

    forEach(Object.keys(get(payload, 'data.onboardingAdmins', [])), id => {
      admin[id] = 'onboardingAdmins'
    })

    return state.merge({
      admin,
      paged,
      isLoadedAdmin: true,
      isLoadingAdmin: false,
    })
  },

  [DELETE_ADMIN.SUCCESS]: (state, { payload }) => {
    const admins = {
      ...state.admins,
    }
    delete admins[payload.deletedId]
    return state.merge({
      admins,
    })
  },

  [LOG_OUT]: state => state.merge(initialState),
}

export default createReducer(initialState, handlers)
