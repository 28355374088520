import {
  createFields,
  createReducerHandlers,
  createLoadHandler,
  createDeleteHandler,
} from 'rapidux'

import { createReducer } from 'Store/utils'

import { LOG_OUT } from 'Store/Actions/viewer'
import {
  LOAD_LABORS,
  LOAD_LABOR,
  CREATE_LABOR,
  DELETE_LABOR,
} from 'Store/Actions/admin/labors'

const initialState = {
  ...createFields('labors'),
  ...createFields('labors', 'labor', true),
  paged: {},
}
const handlers = {
  ...createReducerHandlers('labors', LOAD_LABORS, {
    withReplace: true,
  }),

  ...createReducerHandlers('labors', LOAD_LABOR, {
    withReplace: true,
    mapToKey: 'labor',
    singular: true,
  }),

  [CREATE_LABOR.SUCCESS]: createLoadHandler('labors'),
  [DELETE_LABOR.SUCCESS]: createDeleteHandler('labors'),

  [LOG_OUT]: state => state.merge(initialState),
}

export default createReducer(initialState, handlers)
