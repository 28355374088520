import { createFields, createReducerHandlers, createLoadHandler } from 'rapidux'

import { createReducer } from 'Store/utils'

import { LOG_OUT } from 'Store/Actions/viewer'
import { LOAD_CLIENTS, CREATE_CLIENT } from 'Store/Actions/admin/clients'

const initialState = {
  ...createFields('clients'),
}
const handlers = {
  ...createReducerHandlers('clients', LOAD_CLIENTS, {
    withReplace: true,
  }),

  [CREATE_CLIENT.SUCCESS]: createLoadHandler('clients'),

  [LOG_OUT]: state => state.merge(initialState),
}

export default createReducer(initialState, handlers)
