import qs from 'qs'
import { DateTime } from 'luxon'

import get from 'lodash/get'
import pick from 'lodash/pick'
import head from 'lodash/head'
import padStart from 'lodash/padStart'
import divide from 'lodash/divide'
import round from 'lodash/round'
import reduce from 'lodash/reduce'
import add from 'lodash/add'
import isEqual from 'lodash/isEqual'
import times from 'lodash/times'
import filter from 'lodash/filter'
import isNumber from 'lodash/isNumber'
import inRange from 'lodash/inRange'
import findKey from 'lodash/findKey'
import findIndex from 'lodash/findIndex'
import size from 'lodash/size'
import setWith from 'lodash/setWith'
import clone from 'lodash/clone'

import {
  ADMIN_ROOT,
  APP_ROOT,
  ROOT_PATH,
  ONBOARDING_ROOT,
  ADMIN_PATHS,
} from 'Constants/paths'

import {
  USER_TYPES,
  DAY_INDEXES,
  PROJECT_TIME_ZONES,
  PROJECT_TIME_ZONE_LABELS,
} from 'Constants/types'

import {
  AuthorizedRedirector,
  UnauthorizedRedirector,
} from 'Containers/Redirectors'

export const getFullLocation = location =>
  `${get(location, 'pathname', '/')}${get(location, 'search', '')}${get(
    location,
    'hash',
    '',
  )}`

export const checkConfirmation = location => {
  const { auth_token: authToken, confirmation_token: confirmationToken } = pick(
    qs.parse(location.search.replace('?', '')),
    ['auth_token', 'confirmation_token'],
  )
  if (authToken && confirmationToken) {
    return {
      authToken,
      confirmationToken,
    }
  }
  return false
}

export const getViewerRoot = viewer => {
  if (!viewer.isOnboarded) return ONBOARDING_ROOT

  switch (viewer.type) {
    case USER_TYPES.admins:
    case USER_TYPES.superAdmins:
      return ADMIN_ROOT

    case USER_TYPES.onboardingAdmins:
      return ADMIN_PATHS.LABOR

    case USER_TYPES.users:
      return APP_ROOT

    default:
      return ROOT_PATH
  }
}

export const redirectAuthorized = viewer => component =>
  viewer.isAuthenticated ? AuthorizedRedirector : component

export const redirectUnauthorized = viewer => component =>
  !viewer.isAuthenticated ? UnauthorizedRedirector : component

export const addUSCode = phoneNumber =>
  phoneNumber ? `+1 ${phoneNumber}` : null

export const removeUSCode = phoneNumber => {
  if (/^\+1/.test(phoneNumber)) return phoneNumber.substr(2)
  if (/^1/.test(phoneNumber)) return phoneNumber.substr(1)

  return phoneNumber
}

export const isOnboarded = (viewer, viewerType) =>
  viewerType === USER_TYPES.superAdmins ||
  get(viewer, 'profile.onboardingCompleted')

export const getSelectValue = (value, label) => ({
  value,
  label: label || value,
})

export const getSearchString = string => string.toLowerCase().replace(/ /g, '')

export const getProfileFullName = (firstName = '', lastName) =>
  `${firstName}${lastName ? ` ${lastName}` : ''}`

export const getProfilePhone = profile =>
  get(profile, 'verifiedPrimaryPhoneNumber.internationalFormat') ||
  get(profile, 'pendingPrimaryPhoneNumber.internationalFormat')

export const getCreatedDataId = (result, type) =>
  head(Object.keys(get(result, ['payload', 'data', type], {})))

export const getCardUserId = userId => padStart(userId, 5, '0')

export const getTableCellTotal = ({ column, data }) =>
  round(
    reduce(
      data,
      (acc, row) =>
        isEqual(get(row, '_original.skipTotalCalc'), true)
          ? acc
          : acc + get(row, column.id, 0),
      0,
    ),
    1,
  )

export const getTableCellTotalPercent = ({ column, data }) => {
  if (data.length < 1) return 0

  return `${round(
    divide(getTableCellTotal({ column, data }), data.length),
    1,
  )}%`
}

export const getTableRowTotal = ({ total }) =>
  round(reduce(total, (acc, value) => add(acc, get(value, 'total')), 0), 1)

export const getTableFooterValue = ({ column, data }) =>
  get(head(data), ['_original', 'footer', get(column, 'id')])

export const getWeeklyDisabledDays = weekEndingDay => {
  if (!weekEndingDay) return []

  const dayIndex = isEqual(get(DAY_INDEXES, weekEndingDay), 6)
    ? 0
    : get(DAY_INDEXES, weekEndingDay) + 1

  return filter(
    times(7, index => (isEqual(index, dayIndex) ? undefined : index)),
    isNumber,
  )
}

export const getActualClockTimeStatus = (shiftTime, actualShiftTime) => {
  const shiftDate = DateTime.fromISO(shiftTime, { setZone: true })
  const actualShiftDate = DateTime.fromISO(actualShiftTime, {
    setZone: true,
  })

  if (shiftDate.invalid || actualShiftDate.invalid) {
    return false
  }

  return inRange(
    get(shiftDate.diff(actualShiftDate, 'minutes').toObject(), 'minutes'),
    -15,
    15,
  )
    ? 'within'
    : 'out'
}

export const getProjectDailyTotalHours = (original, data) => {
  const cells = filter(data, {
    userName: get(original, 'userName'),
  })

  const originalIndex = findIndex(cells, {
    checkinId: get(original, 'checkinId'),
  })

  return isEqual(size(cells), add(originalIndex, 1))
    ? reduce(cells, (acc, cell) => add(acc, get(cell, 'totalHours')), 0)
    : null
}

export const getStateCellValue = ({ value, original }) => {
  if (isEqual(get(original, 'profile.onboardingCompleted'), false)) {
    return 'Profile created'
  }

  if (!get(original, 'employeePackage')) {
    return 'Onboarding completed'
  }

  if (isEqual(value, 'deactivated')) {
    return 'Deactivated'
  }

  return 'Active'
}

export const getAdminLaborStatus = entity => {
  if (isEqual(get(entity, 'profile.onboardingCompleted'), false)) {
    return 'Profile created'
  }

  if (!get(entity, 'employeePackage')) {
    return 'Onboarding completed'
  }

  return get(entity, 'state')
}

export const getTimezoneLabelWithOffset = timezoneName => {
  const ianaTimeZone = findKey(PROJECT_TIME_ZONES, v =>
    isEqual(v, timezoneName),
  )

  return `${get(PROJECT_TIME_ZONE_LABELS, timezoneName)} (${DateTime.local()
    .setZone(ianaTimeZone)
    .offsetNameShort.replace('GMT', 'UTC')})`
}

export function setIn(initial, path, value) {
  return setWith(clone(initial), path, value, clone)
}
