import { createFields, createReducerHandlers } from 'rapidux'

import { createReducer } from 'Store/utils'

import { LOG_OUT } from 'Store/Actions/viewer'
import { LOAD_MILITARY_SERVICE_TYPES } from 'Store/Actions/onboarding/military'

const initialState = {
  ...createFields('militaryServiceTypes'),
}
const handlers = {
  ...createReducerHandlers(
    'militaryServiceTypes',
    LOAD_MILITARY_SERVICE_TYPES,
    {
      withReplace: false,
    },
  ),

  [LOG_OUT]: state => state.merge(initialState),
}

export default createReducer(initialState, handlers)
