import logoGlyph from './logo.svg'

import loadingGlyph from './loading.svg'

import closeGlyph from './close.svg'
import magnifyGlyph from './magnify.svg'

import checkMarkStepGlyph from './check-mark-step.svg'
import checkMarkGlyph from './check-mark.svg'
import errorMarkGlyph from './error-mark.svg'

import hideMenuGlyph from './hide-menu.svg'

import chevronGlyph from './chevron.svg'
import chevronLeftGlyph from './chevron-left.svg'
import chevronUp from './chevron-up.svg'
import chevronDown from './chevron-down.svg'

import inputEmailGlyph from './input-email.svg'
import inputPassGlyph from './input-pass.svg'

import onboardingDocGlyph from './onboarding-doc.svg'
import onboardingEqualGlyph from './onboarding-equal.svg'
import onboardingMilitaryGlyph from './onboarding-military.svg'
import onboardingRaceGlyph from './onboarding-race.svg'
import onboardingDocDrive from './onboarding-doc-drive.svg'
import onboardingDocLicense from './onboarding-doc-license.svg'
import onboardingDocPhoto from './onboarding-doc-photo.svg'
import onboardingPhoneGlyp from './onboarding-phone.svg'
import onboardingBadgeGlyph from './onboarding-writing.svg'

import tipInfoGlyph from './tip-info.svg'
import cloudUploadGlyph from './cloud-upload.svg'
import pdfFileGlyph from './pdf-file.svg'

import triangleDownGlyph from './triangle-down.svg'

import calendarGlyph from './calendar.svg'

import pencilGlyph from './pencil.svg'

import alertGlyph from './alert.svg'
import checkCircleGlyph from './check-circle.svg'
import checkMarkButtonGlyph from './check-mark-button.svg'
import informationGlyph from './information.svg'
import informationOutlineGlyph from './information-outline.svg'

import arrowLeftGlyph from './arrow-left.svg'

export {
  logoGlyph,
  loadingGlyph,
  closeGlyph,
  magnifyGlyph,
  checkMarkStepGlyph,
  checkMarkGlyph,
  errorMarkGlyph,
  hideMenuGlyph,
  chevronGlyph,
  chevronLeftGlyph,
  chevronUp,
  chevronDown,
  inputEmailGlyph,
  inputPassGlyph,
  onboardingDocGlyph,
  onboardingEqualGlyph,
  onboardingMilitaryGlyph,
  onboardingRaceGlyph,
  tipInfoGlyph,
  onboardingDocDrive,
  onboardingDocLicense,
  onboardingDocPhoto,
  onboardingPhoneGlyp,
  onboardingBadgeGlyph,
  cloudUploadGlyph,
  pdfFileGlyph,
  triangleDownGlyph,
  calendarGlyph,
  pencilGlyph,
  alertGlyph,
  checkCircleGlyph,
  checkMarkButtonGlyph,
  informationGlyph,
  informationOutlineGlyph,
  arrowLeftGlyph,
}
