import { USER_TYPES } from 'Constants/types'

export const TAB_NAMES = {
  ID_CARD: 'ID_CARD',
  BASIC: 'BASIC',
  PHOTO: 'PHOTO',
  WORK_HISTORY: 'WORK_HISTORY',
}

export const TAB_LABELS = {
  [TAB_NAMES.ID_CARD]: 'ID Card',
  [TAB_NAMES.BASIC]: 'Basic Information',
  [TAB_NAMES.PHOTO]: 'Photo',
  [TAB_NAMES.WORK_HISTORY]: 'Work history',
}

export const ALL_TABS = [
  TAB_NAMES.ID_CARD,
  TAB_NAMES.BASIC,
  TAB_NAMES.PHOTO,
  TAB_NAMES.WORK_HISTORY,
]

export const LABOR_KIND_NAMES = {
  direct: 'Direct Employee',
  subcontractor: 'Sub-Contractor',
}

export const TABS = {
  [USER_TYPES.superAdmins]: ALL_TABS,
  [USER_TYPES.admins]: ALL_TABS,
  [USER_TYPES.onboardingAdmins]: ALL_TABS,
}
