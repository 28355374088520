import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Flex, Box } from 'rebass'

export const FormContainer = styled(Box).attrs({
  m: 4,
  width: 280,
  as: 'form',
})``

export const Error = styled(Box)`
  color: ${themeGet('colors.danger')};
  text-align: center;
`

export const ButtonsContainer = styled(Flex).attrs({ width: 1, mt: 4, pt: 2 })``
