import { connect } from 'react-redux'

import { uploadFile } from 'Store/Actions/uploading'
import { deleteEmployeePackage } from 'Store/Actions/admin/employeePackages'
import EmployeePackageUploader from './EmployeePackageUploader'

export default connect(
  null,
  { onUploadFile: uploadFile, onDelete: deleteEmployeePackage },
)(EmployeePackageUploader)
