import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Flex, Box } from 'rebass'
import { Form } from 'react-final-form'
import validate from 'validate.js'
import { toast } from 'react-toastify'

import {
  presenceFieldConstraint,
  fieldLengthConstraint,
} from 'Constants/constraints'
import { Forms, Toast } from 'Components/Blocks'
import { Title, Button, Modal } from 'Components/UI'

import { Error } from '../styles'

class SubcontractorProvider extends PureComponent {
  state = { error: null }

  get isNew() {
    const { subcontractorProvider } = this.props

    return !subcontractorProvider
  }

  validate = values =>
    validate(values, {
      ...presenceFieldConstraint('name'),
      ...presenceFieldConstraint('address'),
      ...fieldLengthConstraint('ein', 9, 9),
    })

  handleSubmit = async values => {
    const {
      onCreateSubcontractorProvider,
      onEditSubcontractorProvider,
      subcontractorProviderId,
      onCallback,
    } = this.props

    this.setState({ error: null })

    const result = this.isNew
      ? await onCreateSubcontractorProvider(values)
      : await onEditSubcontractorProvider({
          subcontractorProviderId,
          ...values,
        })

    if (result.ok) {
      toast.success(
        <Toast
          heading={`Sub-Contractor Company ${this.isNew ? 'added' : 'edited'}`}
          text={`Sub-Contractor Company info successfully ${
            this.isNew ? 'added' : 'edited'
          }`}
          type="success"
        />,
      )
      onCallback()
    } else {
      this.setState({
        error: 'Something goes wrong. Please try again later...',
      })
    }
  }

  handleCallback = () => {
    const { onCallback } = this.props
    this.setState({ error: null })
    onCallback()
  }

  renderForm = ({ handleSubmit, invalid }) => {
    const { error } = this.state
    const { onCallback } = this.props

    return (
      <Box as="form" p={4} width={545} onSubmit={handleSubmit}>
        <Title.H2 color="brownishGray" mb={4} mt={4} textAlign="center">
          {this.isNew ? 'Add' : 'Edit'} Sub-Contractor Company
        </Title.H2>

        <Forms.Input
          label="Sub-Contractor Company Name"
          name="name"
          placeholder="Enter Sub-Contractor Company Name"
        />

        <Forms.GeosuggestInput
          label="Address"
          name="address"
          placeholder="Enter Address"
        />

        <Forms.Input label="EIN" name="ein" placeholder="Entere EIN" />

        {error && <Error>{error}</Error>}

        <Flex alignItems="center" justifyContent="flex-end" mt={4}>
          <Button default mr={2} onClick={onCallback}>
            Cancel
          </Button>

          <Button disabled={invalid} secondary type="submit">
            {this.isNew ? 'Add' : 'Save'}
          </Button>
        </Flex>
      </Box>
    )
  }

  render() {
    const { isOpen, subcontractorProvider } = this.props

    return (
      <Modal isOpen={isOpen} onCallback={this.handleCallback}>
        <Modal.Close onClick={this.handleCallback} />
        <Form
          initialValues={subcontractorProvider}
          render={this.renderForm}
          validate={this.validate}
          onSubmit={this.handleSubmit}
        />
      </Modal>
    )
  }
}

SubcontractorProvider.defaultProps = {
  subcontractorProvider: null,
  subcontractorProviderId: null,
}

SubcontractorProvider.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  subcontractorProvider: PropTypes.object,
  subcontractorProviderId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onCallback: PropTypes.func.isRequired,
  onCreateSubcontractorProvider: PropTypes.func.isRequired,
  onEditSubcontractorProvider: PropTypes.func.isRequired,
}

export default SubcontractorProvider
