import {
  throttle,
  all,
  put,
  fork,
  select,
  cancel,
  takeEvery,
} from 'redux-saga/effects'
import get from 'lodash/get'
import pick from 'lodash/pick'
import reduce from 'lodash/reduce'
import set from 'lodash/set'
import { DateTime } from 'luxon'

import {
  UPDATE_FILTER_FORM,
  CHANGE_PAGE,
  CHANGE_PAGE_SIZE,
  CHANGE_SORTED,
} from 'Store/Actions/ui'
import { getState as getUiState } from 'Store/Selectors/ui'
import { getState as getTablesState } from 'Store/Selectors/tables'
import { loadAdmins } from 'Store/Actions/admin/admins'
import { loadLabors } from 'Store/Actions/admin/labors'
import { loadProjects } from 'Store/Actions/admin/projects'
import { loadCheckins } from 'Store/Actions/admin/checkins'
import { loadClients } from 'Store/Actions/admin/clients'
import {
  loadSubcontractorProviders,
  loadSubcontractorProviderLabors,
} from 'Store/Actions/admin/subcontractor-providers'

function* makeRequest({ payload }) {
  const action = get(
    {
      admins: loadAdmins,
      labors: loadLabors,
      projects: loadProjects,
      checkins: loadCheckins,
      clients: loadClients,
      subcontractorProviders: loadSubcontractorProviders,
      subcontractorProviderLabors: loadSubcontractorProviderLabors,
    },
    payload.type,
  )

  if (!action) yield cancel()

  const uiState = yield select(getUiState)
  const tablesState = yield select(getTablesState)

  const between = (from, to) => [
    from.set({ hour: 0 }).toISO(),
    (to || from).set({ hour: 23, minute: 59 }).toISO(),
  ]

  yield put(
    action({
      paged: true,
      sort: reduce(
        pick(get(uiState, `${payload.type}Sorted`), ['id', 'desc']),
        (acc, value, key) => {
          if (key === 'id') return `${acc}${value}`
          if (key === 'desc') return `${value ? '' : '-'}${acc}`

          return acc
        },
        '',
      ),
      filters: reduce(
        pick(get(tablesState, `${payload.type}FilterForm`), [
          'status.value',
          'createdAt',
          'lastWorkedAt',
          'date_range',
          'search',
          'laborKind',
          'kind',
          'updatedAt',
          'userId',
          'checkinStatus',
          'startAt',
        ]),
        (acc, value, key) => {
          switch (key) {
            case 'status': {
              switch (value.value) {
                case 'all':
                  break
                case 'profileCreated':
                  set(acc, 'profile.onboardingCompleted.neq', true)
                  set(acc, 'state.neq', 'deactivated')
                  break
                case 'onboardingCompleted':
                  set(acc, 'employeePackage.exists', false)
                  set(acc, 'profile.onboardingCompleted.eq', true)
                  set(acc, 'state.neq', 'deactivated')
                  break
                case 'adminActive':
                case 'laborActive':
                  set(acc, 'employeePackage.exists', true)
                  set(acc, 'profile.onboardingCompleted.eq', true)
                  set(acc, 'state.neq', 'deactivated')
                  break
                case 'adminDeactivated':
                case 'laborDeactivated':
                  set(acc, 'state.eq', 'deactivated')
                  break
                default:
                  set(acc, 'state.eq', value.value)
              }

              break
            }
            case 'laborKind':
              if (value.value !== 'all') {
                set(acc, 'laborKind.eq', value.value)
              }
              break
            case 'kind':
              if (value.value !== 'all') {
                set(acc, 'kind.eq', value.value)
              }
              break
            case 'userId': {
              set(acc, 'userId.eq', value)
              break
            }
            case 'checkinStatus': {
              set(acc, 'status.eq', value)
              break
            }
            case 'date_range': {
              const from = DateTime.fromJSDate(value.from)
              const to = DateTime.fromJSDate(value.to)

              if (!from.invalid && !to.invalid) {
                set(acc, 'date_range.between', between(from, to))
              }

              break
            }
            case 'startAt': {
              const from = DateTime.fromJSDate(value.from)
              const to = DateTime.fromJSDate(value.to)

              if (!from.invalid && !to.invalid) {
                set(acc, 'startAt.between', between(from, to))
              }

              break
            }
            case 'createdAt':
            case 'lastWorkedAt': {
              const from = DateTime.fromJSDate(value.from)
              const to = DateTime.fromJSDate(value.to)

              if (!from.invalid && !to.invalid) {
                set(acc, `${key}.between`, between(from, to))
              }

              break
            }
            case 'updatedAt': {
              const date = DateTime.fromJSDate(value)

              if (!date.invalid) {
                set(acc, 'updatedAt.between', between(date))
              }
              break
            }
            case 'search': {
              if (payload.type === 'projects') {
                set(acc, 'name.ilike', `%${value}%`)
              } else if (
                payload.type === 'clients' ||
                payload.type === 'subcontractorProviders'
              ) {
                set(acc, 'query.search', value)
              } else {
                set(acc, 'profile.fullName.ilike', `%${value}%`)
              }
              break
            }
            default:
              break
          }

          return acc
        },
        {},
      ),
      ...pick(get(yield select(getUiState), `${payload.type}Paged`), [
        'number',
        'size',
      ]),
    }),
  )
}

function* filterFormUpdating() {
  yield throttle(2000, UPDATE_FILTER_FORM, makeRequest)
}

export default function* admins() {
  yield all([
    fork(filterFormUpdating),
    takeEvery([CHANGE_PAGE, CHANGE_PAGE_SIZE, CHANGE_SORTED], makeRequest),
  ])
}
