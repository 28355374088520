import { createFields, createReducerHandlers } from 'rapidux'

import { createReducer } from 'Store/utils'

import { LOG_OUT } from 'Store/Actions/viewer'
import { LOAD_ETHNICITIES } from 'Store/Actions/onboarding/race'

const initialState = {
  ...createFields('ethnicities'),
}
const handlers = {
  ...createReducerHandlers('ethnicities', LOAD_ETHNICITIES, {
    withReplace: false,
  }),

  [LOG_OUT]: state => state.merge(initialState),
}

export default createReducer(initialState, handlers)
