import merge from 'lodash/merge'
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'

import { createAsyncAction } from 'Store/utils'

import apiCall from 'Services/Api'

export const LOAD_ROLES = createAsyncAction('admin/roles/LOAD')
export const CREATE_ROLE = createAsyncAction('admin/roles/CREATE')
export const UPDATE_ROLE = createAsyncAction('admin/roles/UPDATE')
export const ACTIVATE_ROLE = createAsyncAction('admin/roles/ACTIVATE')

const roleIncludes = []

export const loadRoles = ({
  paged = false,
  number = 1,
  size = 1000,
  sort,
  filters,
} = {}) => (dispatch, getState) => {
  const params = {
    endpoint: '/admin/roles',
    types: LOAD_ROLES,
    query: {
      include: roleIncludes.join(),
      filter: filters,
    },
  }

  if (paged) {
    merge(params, {
      paged,
      query: pickBy(
        {
          include: roleIncludes.join(),
          page: {
            number,
            size,
          },
          filter: filters,
          sort,
        },
        identity,
      ),
      payload: {
        paged: {
          number,
          size,
        },
      },
    })
  }

  return apiCall(params)(dispatch, getState)
}

export const createRole = ({ name, kind, state = 'active' }) =>
  apiCall({
    method: 'POST',
    endpoint: '/admin/roles',
    types: CREATE_ROLE,
    query: {
      include: roleIncludes.join(),
      data: {
        type: 'roles',
        attributes: pickBy({ name, kind, state }, identity),
      },
    },
  })

export const updateRole = ({ id, name, kind, state = 'active' }) =>
  apiCall({
    method: 'PATCH',
    endpoint: `/admin/roles/${id}`,
    types: UPDATE_ROLE,
    query: {
      include: roleIncludes.join(),
      data: {
        type: 'roles',
        attributes: pickBy({ name, kind, state }, identity),
      },
    },
  })

export const activateRole = ({ id, name, kind, state = 'active' }) =>
  apiCall({
    method: state === 'active' ? 'POST' : 'DELETE',
    endpoint: `/admin/roles/${id}/activation`,
    types: ACTIVATE_ROLE,
    query: {
      include: roleIncludes.join(),
      data: {
        type: 'roles',
        attributes: pickBy({ name, kind, state }, identity),
      },
    },
  })
