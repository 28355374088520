import merge from 'lodash/merge'
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'

import { createAsyncAction } from 'Store/utils'
import { PROFILE_INCLUDES } from 'Constants/includes'
import apiCall from 'Services/Api'
import { transformToRequest } from 'Services/Entities/UserProfile'
import { getAdminId } from 'Store/Selectors/admin/admins'

export const LOAD_ADMINS = createAsyncAction('admin/admins/LOAD')
export const LOAD_ADMIN = createAsyncAction('admins/admin/LOAD')
export const INVITE_ADMIN = createAsyncAction('admin/admins/INVITE')
export const CREATE_ADMIN = createAsyncAction('admin/admins/CREATE')
export const ACTIVATE_ADMIN = createAsyncAction('admin/admin/ACTIVATE')
export const DEACTIVATE_ADMIN = createAsyncAction('admin/admin/DEACTIVATE')
export const UPDATE_ADMIN = createAsyncAction('admin/admin/UPDATE')
export const DELETE_ADMIN = createAsyncAction('admin/admins/DELETE')
export const SEND_INVITE_TO_ADMIN = createAsyncAction('admin/admin/invite/SEND')

const adminIncludes = [
  'profile',
  'emailCredential',
  'kind',
  ...PROFILE_INCLUDES.map(fieldName => `profile.${fieldName}`),
]

export const loadAdmins = ({
  paged = false,
  number = 1,
  size = 10,
  sort,
  filters,
} = {}) => (dispatch, getState) => {
  const params = {
    endpoint: '/admin/admins',
    types: LOAD_ADMINS,
    query: {
      include: [...adminIncludes, 'employeePackage'].join(),
    },
  }

  if (paged) {
    merge(params, {
      paged,
      query: pickBy(
        {
          include: [...adminIncludes, 'employeePackage'].join(),
          page: {
            number,
            size,
          },
          filter: filters,
          sort,
        },
        identity,
      ),
      payload: {
        paged: {
          number,
          size,
        },
      },
    })
  }

  return apiCall(params)(dispatch, getState)
}

export const inviteAdmin = ({
  firstName,
  lastName,
  email,
  phoneNumber,
  kind,
}) =>
  apiCall({
    method: 'POST',
    endpoint: '/admin/admins/invite',
    query: {
      data: {
        type: 'adminInvitations',
        attributes: pickBy(
          {
            firstName,
            lastName,
            email,
            phoneNumber,
            kind,
          },
          identity,
        ),
      },
    },
    types: INVITE_ADMIN,
  })

export const sendInvite = adminId =>
  apiCall({
    method: 'POST',
    endpoint: `/admin/admins/${adminId}/invite`,
    types: SEND_INVITE_TO_ADMIN,
    query: {
      include: [],
    },
  })

export const createAdmin = ({ firstName, lastName, email, phoneNumber }) =>
  apiCall({
    method: 'POST',
    endpoint: '/admin/admins',
    types: CREATE_ADMIN,
    query: {
      include: adminIncludes.join(),
      data: {
        type: 'admins',
        attributes: pickBy(
          { firstName, lastName, email, phoneNumber },
          identity,
        ),
      },
    },
  })

export const loadAdmin = adminId =>
  apiCall({
    endpoint: `/admin/admins/${adminId}`,
    types: LOAD_ADMIN,
    query: {
      include: [...adminIncludes, 'qrcode', 'idCard', 'employeePackage'].join(),
    },
  })

export const activateAdmin = adminId =>
  apiCall({
    method: 'POST',
    endpoint: `/admin/admins/${adminId}/activation`,
    types: ACTIVATE_ADMIN,
    query: {
      include: [...adminIncludes, 'qrcode', 'idCard'].join(),
    },
  })

export const deactivateAdmin = adminId =>
  apiCall({
    method: 'DELETE',
    endpoint: `/admin/admins/${adminId}/activation`,
    types: DEACTIVATE_ADMIN,
    query: {
      include: [...adminIncludes, 'qrcore', 'idCard'].join(),
    },
  })

export const updateAdmin = values => (dispatch, getState) => {
  const adminId = getAdminId(getState())

  return dispatch(
    apiCall({
      method: 'PATCH',
      endpoint: `/admin/admins/${adminId}/profile`,
      types: UPDATE_ADMIN,
      query: {
        include: PROFILE_INCLUDES.join(),
        data: {
          type: 'profiles',
          ...transformToRequest(values),
        },
      },
    }),
  )
}

export const deleteAdmin = adminId =>
  apiCall({
    method: 'DELETE',
    endpoint: `/admin/admins/${adminId}`,
    types: DELETE_ADMIN,
    payload: {
      deletedId: adminId,
    },
  })
