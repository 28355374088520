import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import validate from 'validate.js'
import { Form } from 'react-final-form'
import { Text, Flex, Box } from 'rebass'
import { toast } from 'react-toastify'

import { Modal, Title, Button } from 'Components/UI'
import { Select, TextArea } from 'Components/Blocks/Forms'
import { SHIFT_TIME_OPTIONS } from 'Constants/reports'
import { presenceFieldConstraint } from 'Constants/constraints'
import { Toast } from 'Components/Blocks'
import { DateTime } from 'luxon'
import { Error } from '../styles'

const FORMAT = 'LL/dd/yyyy (ccc) HH:mm'

class ShiftTime extends PureComponent {
  state = { error: null, isLoading: false }

  validate = values =>
    validate(values, {
      ...presenceFieldConstraint('startChangeReason', !!values.startAt),
      ...presenceFieldConstraint('finishChangeReason', !!values.finishAt),
    })

  handleCallback = () => {
    const { onCallback } = this.props

    this.setState({ error: null, isLoading: false })
    onCallback()
  }

  handleSubmit = async values => {
    const {
      onUpdateShiftTime,
      onSuccess,
      projectDate,
      projectId,
      checkinId,
      clientName,
      projectTimeZone,
    } = this.props

    this.setState({ isLoading: true })
    const { ok } = await onUpdateShiftTime({
      ...values,
      date: projectDate,
      projectTimeZone,
      checkinId,
      projectId,
    })

    if (ok) {
      toast.success(
        <Toast
          heading={`Time for ${clientName} on ${projectDate} was successfully changed`}
          type="success"
        />,
      )

      this.handleCallback()
      onSuccess()
    } else {
      this.setState({ error: 'Something goes wrong' })
    }

    this.setState({ isLoading: false })
  }

  renderForm = ({ handleSubmit, invalid, pristine }) => {
    const { error, isLoading } = this.state
    const {
      actualShiftStart,
      actualShiftEnd,
      projectName,
      projectDate,
      clientName,
    } = this.props

    return (
      <Box as="form" width={769} onSubmit={handleSubmit}>
        <Title.H3 color="darkTeal" mb={4} mt={5} textAlign="center">
          Shift Time Editing
        </Title.H3>

        <Flex
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          mb={4}
        >
          <Flex>
            <Text color="darkTeal">Project Name:</Text>
            <Text color="darkTeal" fontWeight="bold" ml={1}>
              {projectName}
            </Text>
          </Flex>
          <Flex>
            <Text color="darkTeal">Date:</Text>
            <Text color="darkTeal" fontWeight="bold" ml={1}>
              {projectDate}
            </Text>
          </Flex>
          <Flex>
            <Text color="darkTeal">Labor Name:</Text>
            <Text color="darkTeal" fontWeight="bold" ml={1}>
              {clientName}
            </Text>
          </Flex>
        </Flex>

        <Flex>
          <Box pr={2} width={1 / 2}>
            <Select
              label="Shift Start Time"
              name="startAt"
              options={SHIFT_TIME_OPTIONS}
              placeholder=""
            />

            {actualShiftStart && (
              <Flex mb={3}>
                <Text color="darkTeal">Actual Clock-in Time:</Text>
                <Text color="darkTeal" fontWeight="bold" ml={1}>
                  {DateTime.fromISO(actualShiftStart, {
                    setZone: true,
                  }).toFormat(FORMAT)}
                </Text>
              </Flex>
            )}

            <TextArea label="Reason" name="startChangeReason" />
          </Box>

          <Box pl={2} width={1 / 2}>
            <Select
              label="Shift End Time"
              name="finishAt"
              options={SHIFT_TIME_OPTIONS}
              placeholder=""
            />

            {actualShiftEnd && (
              <Flex mb={3}>
                <Text color="darkTeal">Actual Clock-out Time:</Text>
                <Text color="darkTeal" fontWeight="bold" ml={1}>
                  {DateTime.fromISO(actualShiftEnd, {
                    setZone: true,
                  }).toFormat(FORMAT)}
                </Text>
              </Flex>
            )}

            <TextArea label="Reason" name="finishChangeReason" />
          </Box>
        </Flex>

        {error && <Error>{error}</Error>}

        <Flex alignItems="center" justifyContent="flex-end" mb={3} mt={4}>
          <Button default mr={2} onClick={this.handleCallback}>
            Cancel
          </Button>

          <Button
            disabled={invalid || pristine || isLoading}
            secondary
            type="submit"
          >
            Save
          </Button>
        </Flex>
      </Box>
    )
  }

  render = () => {
    const { isOpen } = this.props

    return (
      <Modal
        isOpen={isOpen}
        isOverflow={false}
        onCallback={this.handleCallback}
      >
        <Modal.Close onClick={this.handleCallback} />

        <Form
          render={this.renderForm}
          validate={this.validate}
          onSubmit={this.handleSubmit}
        />
      </Modal>
    )
  }
}

ShiftTime.defaultProps = {
  actualShiftStart: null,
  actualShiftEnd: null,
  projectId: null,
  checkinId: null,
  projectName: null,
  projectDate: null,
  clientName: null,
  projectTimeZone: null,
}

ShiftTime.propTypes = {
  actualShiftEnd: PropTypes.string,
  actualShiftStart: PropTypes.string,
  checkinId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  clientName: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  projectDate: PropTypes.string,
  projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  projectName: PropTypes.string,
  projectTimeZone: PropTypes.string,
  onCallback: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onUpdateShiftTime: PropTypes.func.isRequired,
}

export default ShiftTime
