import React, { Suspense } from 'react'
import PropTypes from 'prop-types'
import { Flex } from 'rebass'

import { Loader, Steps, Button } from 'Components/UI'

import { STEP_NAMES } from 'Constants/onboarding'

import { Container } from './styles'

const OnboardingForm = ({
  children,
  form,
  allSteps,
  completedSteps,
  disabledSteps,
  step,
  onChangeStep,
  onClickBack,
}) => (
  <Suspense fallback={<Loader />}>
    <Flex justifyContent="center" mb={5}>
      <Steps
        allSteps={allSteps}
        completed={completedSteps}
        disabled={disabledSteps}
        step={step}
        onChangeStep={onChangeStep}
      />
    </Flex>

    <form onSubmit={form.handleSubmit}>
      <Container>{children}</Container>

      <Flex justifyContent="flex-end" mt={5}>
        {step !== STEP_NAMES.BASIC && (
          <Button mr={2} type="button" white onClick={onClickBack}>
            Back
          </Button>
        )}
        <Button disabled={form.submitting} secondary type="submit">
          Next
        </Button>
      </Flex>
    </form>
  </Suspense>
)

OnboardingForm.propTypes = {
  allSteps: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.node.isRequired,
  completedSteps: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabledSteps: PropTypes.arrayOf(PropTypes.string).isRequired,
  form: PropTypes.object.isRequired,
  step: PropTypes.string.isRequired,
  onChangeStep: PropTypes.func.isRequired,
  onClickBack: PropTypes.func.isRequired,
}

export default OnboardingForm
