import { lazy } from 'react'

const Landing = lazy(() =>
  import('./Landing' /* webpackChunkName: "public-landing" */),
)

const PrivacyPolicy = lazy(() =>
  import('./PrivacyPolicy' /* webpackChunkName: "public-privacy-policy" */),
)

const Support = lazy(() =>
  import('./Support' /* webpackChunkName: "public-support" */),
)

export { Landing, PrivacyPolicy, Support }
