import React, { Fragment, PureComponent } from 'react'
import { PropTypes } from 'prop-types'

import get from 'lodash/get'
import head from 'lodash/head'
import keys from 'lodash/keys'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import some from 'lodash/some'

import { Flex } from 'rebass'
import { toast } from 'react-toastify'

import { Button, Text, Icon } from 'Components/UI'
import { Container, Tooltip } from 'Components/Blocks/Forms/Input/styles'
import { closeGlyph } from 'Assets/Svg'
import { Toast } from 'Components/Blocks'
import { Input, Thumbnail, Preview, RemoveButton } from './styles'

const UPLOAD_ERRORS_MESSAGES = [
  {
    error: 'size must be within 1 - 10485760',
    text: 'Please select correct file size for your image',
  },
]

class DocUploader extends PureComponent {
  state = { isLoading: false }

  get buttonLabel() {
    const { isLoading } = this.state
    const { image } = this.props

    if (isLoading) return 'Loading...'
    if (image) return 'Change photo'

    return 'Upload'
  }

  handleChange = async e => {
    const { onUploadFile, input } = this.props
    const file = head(get(e, 'target.files', []))

    if (file) {
      this.setState({ isLoading: true })

      const { ok, payload, data } = await onUploadFile('/images', file)

      if (ok) {
        this.setState({ isLoading: false })

        const imageId = head(keys(get(payload, 'data.images', {})))
        if (imageId) input.onChange(imageId)
      } else {
        const errors = get(data, 'body.errors', [])

        const uploadError = find(UPLOAD_ERRORS_MESSAGES, v => {
          return some(errors, err => err.detail === v.error)
        })

        if (!isEmpty(uploadError)) {
          toast.error(<Toast text={uploadError.text} type="error" />)
        } else {
          toast.error(
            <Toast
              heading="Something goes wrong"
              text="Cannot upload image"
              type="error"
            />,
          )
        }
        this.setState({ isLoading: false })
      }
    }
  }

  handleRemove = () => {
    const { input } = this.props

    input.onChange(null)
  }

  render = () => {
    const { meta, title, glyph, image } = this.props
    const { isLoading } = this.state
    const error = meta.touched && meta.error ? 1 : 0

    return (
      <Fragment>
        <Text color="brownishGray" fontWeight="bold" textAlign="center">
          {title}
        </Text>

        <Thumbnail my={3}>
          {image ? (
            <Fragment>
              <RemoveButton m={2} onClick={this.handleRemove}>
                <Icon fill="white" glyph={closeGlyph} size={16} />
              </RemoveButton>
              <Preview value={get(image, 'content.medium.url', '')} />
            </Fragment>
          ) : (
            <Icon glyph={glyph} height={100} />
          )}
        </Thumbnail>

        <Container error={error}>
          <Flex justifyContent="center">
            <Button disabled={isLoading} height={40} secondary width={168}>
              <Input onChange={this.handleChange} />
              {this.buttonLabel}
            </Button>
          </Flex>

          {error === 1 && <Tooltip>{meta.error.map(item => item)}</Tooltip>}
        </Container>
      </Fragment>
    )
  }
}

DocUploader.defaultProps = { image: null }
DocUploader.propTypes = {
  glyph: PropTypes.object.isRequired,
  image: PropTypes.object,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  onUploadFile: PropTypes.func.isRequired,
}

export default DocUploader
