import React from 'react'

const Projects = React.lazy(() =>
  import('./Projects' /* webpackChunkName: "admin-projects" */),
)

const Project = React.lazy(() =>
  import('./Projects/Project' /* webpackChunkName: "admin-projects-project" */),
)

const Dashboard = React.lazy(() =>
  import('./Dashboard' /* webpackChunkName: "admin-dashboard" */),
)

const Admins = React.lazy(() =>
  import('./Admins' /* webpackChunkName: "admin-admins" */),
)

const Admin = React.lazy(() =>
  import('./Admins/Admin' /* webpackChunkName: "admin-admins-admin" */),
)

const Invitations = React.lazy(() =>
  import('./Invitations' /* webpackChunkName: "admin-invitations" */),
)

const Labors = React.lazy(
  () => import('./Labors') /* webpackChunkName: "admin-labors" */,
)

const Labor = React.lazy(
  () => import('./Labors/Labor') /* webpackChunkName: "admin-labors-labor" */,
)

const Reports = React.lazy(
  () => import('./Reports') /* webpackChunkName: "admin-reports" */,
)

const Clients = React.lazy(
  () => import('./Clients') /* webpackChunkName: "admin-clients" */,
)

const SubcontractorProviders = React.lazy(
  () =>
    import(
      './SubcontractorProviders'
    ) /* webpackChunkName: "admin-subcontractor-providers" */,
)

const SubcontractorProvider = React.lazy(
  () =>
    import(
      './SubcontractorProviders/SubcontractorProvider'
    ) /* webpackChunkName: "admin-subcontractor-providers-provider" */,
)

const Roles = React.lazy(
  () => import('./Roles') /* webpackChunkName: "admin-roles" */,
)

export {
  Projects,
  Project,
  Dashboard,
  Admins,
  Admin,
  Invitations,
  Labors,
  Labor,
  Reports,
  Clients,
  SubcontractorProviders,
  SubcontractorProvider,
  Roles,
}
