import { ONBOARDING_PATHS } from 'Constants/paths'

import * as OnboardingPages from 'Containers/Pages/Onboarding'

const routes = () => [
  /* App */
  {
    path: ONBOARDING_PATHS.BASIC,
    exact: true,
    component: OnboardingPages.Basic,
  },
  {
    path: ONBOARDING_PATHS.PHOTO,
    exact: true,
    component: OnboardingPages.Photo,
  },
  {
    path: ONBOARDING_PATHS.PHONE,
    exact: true,
    component: OnboardingPages.Phone,
  },
  {
    path: ONBOARDING_PATHS.PHONE_VERIFICATION,
    exact: true,
    component: OnboardingPages.PhoneVerification,
  },
  {
    path: ONBOARDING_PATHS.BADGE_AKNOWLEDGMENT,
    exact: true,
    component: OnboardingPages.BadgeAknowledgement,
  },
]

export default routes
