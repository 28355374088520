import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import { toast } from 'react-toastify'
import { DateTime } from 'luxon'
import qs from 'qs'

import { getEnv } from 'Services/Envs'
import { apiHttps } from 'Config/app'
import { Button } from 'Components/UI'
import { REPORT_TYPES } from 'Constants/types'
import { Toast } from 'Components/Blocks'

const getUrl = (endpoint, query) =>
  `${apiHttps}://${getEnv('API_URL')}${endpoint}?${qs.stringify(query, {
    arrayFormat: 'brackets',
  })}`

class AdminReportDownload extends PureComponent {
  handleDownload = async () => {
    const { onLoadToken, reportType, values } = this.props

    const { ok, payload } = await onLoadToken()

    if (ok) {
      const token = get(payload, 'data.token')

      if (token) {
        if (isEqual(reportType, REPORT_TYPES.laborTimeCard)) {
          const laborId = get(values, 'labor.id')
          const startDate = get(values, 'startDate')

          window.open(
            getUrl(`/files/admin/labors/${laborId}/time_card`, {
              token,
              startDate: DateTime.fromJSDate(startDate).toISODate(),
              finishDate: DateTime.fromJSDate(startDate)
                .plus({ days: 6 })
                .toISODate(),
            }),
          )
        }

        if (isEqual(reportType, REPORT_TYPES.employeeWeeklyPayrollReport)) {
          const startDate = get(values, 'startDate')

          window.open(
            getUrl('/files/admin/reports/employee_weekly_payroll', {
              token,
              startDate: DateTime.fromJSDate(startDate).toISODate(),
              finishDate: DateTime.fromJSDate(startDate)
                .plus({ days: 6 })
                .toISODate(),
            }),
          )
        }

        if (isEqual(reportType, REPORT_TYPES.subcontractorWeeklyReport)) {
          const startDate = get(values, 'startDate')
          const subcontractorProviderId = get(
            values,
            'subcontractorProvider.id',
          )

          window.open(
            getUrl('/files/admin/reports/subcontractor_weekly', {
              token,
              subcontractorProviderId,
              startDate: DateTime.fromJSDate(startDate).toISODate(),
              finishDate: DateTime.fromJSDate(startDate)
                .plus({ days: 6 })
                .toISODate(),
            }),
          )
        }

        if (isEqual(reportType, REPORT_TYPES.subcontractorCostCodeReport)) {
          const startDate = get(values, 'startDate')
          const subcontractorProviderId = get(
            values,
            'subcontractorProvider.id',
          )

          window.open(
            getUrl('/files/admin/reports/subcontractor_cost_code', {
              token,
              subcontractorProviderId,
              startDate: DateTime.fromJSDate(startDate).toISODate(),
              finishDate: DateTime.fromJSDate(startDate)
                .plus({ days: 6 })
                .toISODate(),
            }),
          )
        }

        if (isEqual(reportType, REPORT_TYPES.projectDailyReportInternal)) {
          const date = get(values, 'reportDate')

          window.open(
            getUrl('/files/admin/reports/projects/daily_report_internal', {
              token,
              date: DateTime.fromJSDate(date).toISODate(),
              project_id: get(values, 'project.id'),
            }),
          )
        }

        if (isEqual(reportType, REPORT_TYPES.projectDailyReportExternal)) {
          const date = get(values, 'reportDate')

          window.open(
            getUrl('/files/admin/reports/projects/daily_report_external', {
              token,
              date: DateTime.fromJSDate(date).toISODate(),
              project_id: get(values, 'project.id'),
            }),
          )
        }

        if (isEqual(reportType, REPORT_TYPES.projectWeeklyReport)) {
          const projectId = get(values, 'project.id')
          const startDate = get(values, 'startDate')

          window.open(
            getUrl('/files/admin/reports/projects/weekly_report', {
              token,
              projectId,
              startDate: DateTime.fromJSDate(startDate).toISODate(),
              finishDate: DateTime.fromJSDate(startDate)
                .plus({ days: 6 })
                .toISODate(),
            }),
          )
        }

        if (isEqual(reportType, REPORT_TYPES.projectDailyFormanNotes)) {
          const projectId = get(values, 'project.id')
          const date = get(values, 'reportDate')

          window.open(
            getUrl('/files/admin/reports/projects/daily_forman_note', {
              token,
              projectId,
              date: DateTime.fromJSDate(date).toISODate(),
            }),
          )
        }

        if (isEqual(reportType, REPORT_TYPES.employeeHoursReport)) {
          const reportDate = get(values, 'reportDate')

          window.open(
            getUrl('/files/admin/reports/employee_hours', {
              token,
              start_date: DateTime.fromJSDate(reportDate.from).toISODate(),
              finish_date: DateTime.fromJSDate(reportDate.to).toISODate(),
            }),
          )
        }
      }
    } else {
      toast.error(
        <Toast
          heading="Something goes wrong"
          text="Cannot download report"
          type="error"
        />,
      )
    }
  }

  handlePrint = () => {
    window.print()
  }

  render = () => {
    const { disabled } = this.props

    return (
      <Fragment>
        <Button
          disabled={disabled}
          mr={3}
          secondary
          onClick={this.handleDownload}
        >
          Download
        </Button>
      </Fragment>
    )
  }
}

AdminReportDownload.defaultProps = {
  reportType: null,
  values: null,
}

AdminReportDownload.propTypes = {
  disabled: PropTypes.bool.isRequired,
  reportType: PropTypes.string,
  values: PropTypes.object,
  onLoadToken: PropTypes.func.isRequired,
}

export default AdminReportDownload
