import actionDeactivateGlyph from './action-deactivate.svg'
import actionEditGlyph from './action-edit.svg'
import actionMenuGlyph from './action-menu.svg'
import paginationChevronGlyph from './pagination-chevron.svg'
import sortArrowGlyph from './sort-arrow.svg'

export {
  actionDeactivateGlyph,
  actionEditGlyph,
  actionMenuGlyph,
  paginationChevronGlyph,
  sortArrowGlyph,
}
