export const PROFILE_INCLUDES = [
  'ethnicity',
  'militaryServiceType',
  'personalPhoto',
  'usState',
  'socialSecurityCardPhoto',
  'driverLicensePhoto',
  'driverLicenseState',
  'pendingPrimaryPhoneNumber',
  'verifiedPrimaryPhoneNumber',
]

export default {}
