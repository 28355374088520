import { createReducer } from 'Store/utils'

import { LOG_OUT } from 'Store/Actions/viewer'
import { LOAD_INVITATIONS } from 'Store/Actions/admin/invitations'

const initialState = {
  laborInvitations: [],
  adminInvitations: [],
  isLoading: false,
  isLoaded: false,
  paged: {},
}
const handlers = {
  [LOAD_INVITATIONS.SUCCESS]: (state, { payload, paged }) =>
    state.merge({
      laborInvitations: Object.keys(payload.data.laborInvitations || {}),
      adminInvitations: Object.keys(payload.data.adminInvitations || {}),
      paged,
      isLoaded: true,
      isLoading: false,
    }),

  [LOG_OUT]: state => state.merge(initialState),
}

export default createReducer(initialState, handlers)
